import { Icon, Stack, Tag, Title } from "@introist/react-foundation/v2";

import { BatchEmployee } from "../useBatches";
import styled from "styled-components";
import { ProgressBar } from "../../../components/molecules";

const StyledProgressBar = styled(ProgressBar)`
  height: 3px;
  width: 2rem;
  border-radius: 3px;

  > span {
    background-color: var(--palette-success-default);
  }
`;

export const BatchEmployeeStatusTag = ({ employee }: { employee: BatchEmployee }) => {
  if (!employee.resolution && !employee.automationId) return <Title>No automation yet</Title>;

  const resolveContent = () => {
    if (employee.resolution === "completed") {
      return <Tag colorVariant="success">Completed</Tag>;
    }
    if (employee.resolution === "canceled") {
      return <Tag colorVariant="surface">Canceled</Tag>;
    }

    return (
      <Stack>
        {employee.failingStepCount !== undefined && employee.failingStepCount! > 0 && (
          <Icon danger name="warning" />
        )}
        {employee.completedStepCount !== undefined && employee.totalStepCount !== undefined && (
          <Stack>
            <Title variant="bold">
              {employee.completedStepCount!} of {employee.totalStepCount!}
            </Title>
            <StyledProgressBar
              value={employee.completedStepCount!}
              total={employee.totalStepCount!}
            />
          </Stack>
        )}
      </Stack>
    );
  };

  return (
    <Stack
      onClick={
        employee.automationId
          ? () => window.open(`/automations/${employee.automationId}`, "_blank")
          : undefined
      }
      style={{ cursor: employee.automationId ? "pointer" : undefined }}
    >
      {resolveContent()}
    </Stack>
  );
};
