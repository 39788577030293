import { JourneysTimelineItem } from "../JourneysTimeline";
import { useState } from "react";

export type TooltipData = {
  entry: JourneysTimelineItem | null;
  x: number;
  y: number;
  visible: boolean;
};

export const useChartTooltip = () => {
  const [tooltip, setTooltip] = useState<TooltipData>({ entry: null, x: 0, y: 0, visible: false });

  const showTooltip = (entry: JourneysTimelineItem, x: number, y: number) => {
    setTooltip({ entry, x, y, visible: true });
  };

  const hideTooltip = () => {
    setTooltip(prev => ({ ...prev, visible: false }));
  };

  return { tooltip, showTooltip, hideTooltip };
};
