import { DataForm, Select, Title, useTheme } from "@introist/react-foundation/v2";

import { Block } from "components/atoms";
import { prefixForm } from "../../../../../../utils/FormUtils";
import { useMemo } from "react";

import editorStyles from "../WorkflowStepEditor.module.scss";
import { useIntegrationsApi } from "services/api/IntegrationsApi";
import { InstructionEmailEditor } from "./InstructionEmailEditor";

export interface Props {
  form: DataForm<any>;
  formPrefix?: string;
  readonly?: boolean;
}

export const CreateGoogleAccountEditor = ({ form, formPrefix, readonly }: Props) => {
  const { theme } = useTheme();

  const prefixedForm = prefixForm(form, formPrefix);

  const integrationApi = useIntegrationsApi();
  const { data: integrationOptions } = integrationApi.loadOptions({
    integrationId: "googleworkspace"
  }).query;

  const emailOptions = useMemo(() => {
    return [
      { key: "{{firstname}}.{{lastname}}", title: "firstname.lastname" },
      { key: "{{firstname}}", title: "firstname" }
    ];
  }, []);

  const domainOptions = useMemo(() => {
    if (!integrationOptions) return [];
    return integrationOptions.domains.map((domain: any) => ({ key: domain.domainName }));
  }, [integrationOptions]);

  return (
    <>
      <Block>
        {!readonly && (
          <Title>This is the email address that will be created for the Employee.</Title>
        )}
        {readonly && <Title>This is the email address that was created for the Employee.</Title>}
        <div className={editorStyles.DrawerGrid} style={{ marginTop: theme.spacing.xLarge }}>
          <Title>Email</Title>
          {prefixedForm.get("username") ? (
            <Title variant="bold">{prefixedForm.get("username")}</Title>
          ) : (
            <div style={{ display: "flex", alignItems: "center", gap: theme.spacing.small }}>
              <Select
                size="small"
                placeholder="User's email"
                options={emailOptions}
                value={prefixedForm.get("emailTemplate")}
                onSelect={opt => prefixedForm.set("emailTemplate")(opt.key)}
                error={form.isError("emailTemplate")}
                readonly={readonly}
                variant="blended"
                style={{ width: "240px" }}
              />
              <Title>@</Title>
              <Select
                size="small"
                placeholder="Domain"
                options={domainOptions}
                value={prefixedForm.get("domain")}
                onSelect={opt => prefixedForm.set("domain")(opt.key)}
                error={form.isError("domain")}
                readonly={readonly}
                variant="blended"
              />
            </div>
          )}
        </div>
      </Block>
      <InstructionEmailEditor prefixedForm={prefixedForm} readonly={readonly} />
    </>
  );
};
