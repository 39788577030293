export const CreateGoogleAccountStepSchemaFields = {
  emailTemplate: {
    type: "string",
    required: true
  },
  domain: {
    type: "string",
    required: true
  },
  instructionMessageTemplate: {
    from: {
      type: "string",
      required: true
    },
    subject: {
      type: "string",
      required: true
    },
    message: {
      type: "string",
      required: true
    }
  }
};
