import { useEffect, useRef, useState } from "react";
import { ResizeObserver } from "@juggle/resize-observer";

type ChartDimensionsArgs = {
  width?: number;
  height?: number;
  marginTop?: number;
  marginRight?: number;
  marginBottom?: number;
  marginLeft?: number;
};

const combineChartDimensions = (dims: ChartDimensionsArgs) => {
  const parsed = {
    ...dims,
    marginTop: dims.marginTop || 0,
    marginRight: dims.marginRight || 0,
    marginBottom: dims.marginBottom || 0,
    marginLeft: dims.marginLeft || 0
  };

  return {
    ...parsed,
    boundedWidth: Math.max((parsed.width ?? 0) - parsed.marginLeft - parsed.marginRight, 0),
    boundedHeight: Math.max((parsed.height ?? 0) - parsed.marginTop - parsed.marginBottom, 0)
  };
};

export const useChartDimensions = (passedSettings: ChartDimensionsArgs) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const dimensions = combineChartDimensions(passedSettings);

  const [width, setWidth] = useState(passedSettings.width || 0);
  const [height, setHeight] = useState(passedSettings.height || 0);

  useEffect(() => {
    if (dimensions.width && dimensions.height) {
      return;
    }

    const element = ref.current;
    if (element) {
      const resizeObserver = new ResizeObserver(entries => {
        if (!Array.isArray(entries)) return;
        if (!entries.length) return;

        const entry = entries[0];
        if (width !== entry.contentRect.width) setWidth(entry.contentRect.width);
        if (height !== entry.contentRect.height) setHeight(entry.contentRect.height);
      });

      resizeObserver.observe(element);

      return () => resizeObserver.unobserve(element);
    }
  }, [dimensions.height, dimensions.width, height, width]);

  const newSettings = combineChartDimensions({
    ...dimensions,
    width: dimensions.width || width,
    height: dimensions.height || height
  });

  return {
    ref,
    dimensions: newSettings
  };
};
