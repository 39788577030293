import { useCallback, useRef, useState } from "react";
import styled from "styled-components";
import {
  Popover,
  Card,
  useTheme,
  Layout,
  Button,
  Icon,
  Stack,
  Tooltip
} from "@introist/react-foundation/v2";
import { useNavigate } from "react-router-dom";

import { JourneyProgress } from "modules/journeys";

import { FittedTitle } from "components/atoms";
import { Automation } from "../../../../automations/hooks/useAutomations";
import { AUTOMATION_ROUTES } from "../../../../automations/routes";

type EmployeeAutomationSelectorProps = {
  automations: Automation[];
};

const StyledSelector = styled(Layout.Group)`
  user-select: none;
`;

export const EmployeeAutomationSelector = ({
  automations,
  ...rest
}: EmployeeAutomationSelectorProps) => {
  const { theme } = useTheme();
  const popoverAnchorRef = useRef<HTMLButtonElement | null>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  if (automations.length === 0) return null;

  const sorted = automations.sort((a, b) => {
    // sort by failing
    return Number(b.status === "failing") - Number(a.status === "failing");
  });

  const hasFailing = sorted.some(a => a.status === "failing");

  return (
    <StyledSelector {...rest}>
      {sorted.length === 1 && <AutomationInfoRow automation={automations[0]} />}
      {sorted.length > 1 && (
        <Button
          active={popoverOpen}
          size="small"
          variant="blended"
          endIcon="chevronDown"
          ref={popoverAnchorRef}
          startAdornment={
            hasFailing && (
              <Tooltip tooltip="Failing steps">
                <Icon name="warning" color={theme.palette.danger.default} />
              </Tooltip>
            )
          }
          onClick={e => {
            setPopoverOpen(!popoverOpen);
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {sorted.length} Automations
        </Button>
      )}
      <Popover
        referenceElement={popoverAnchorRef.current}
        open={popoverOpen}
        placement="bottom-end"
        onClose={() => setPopoverOpen(false)}
      >
        <Card elevated>
          <Stack vertical gap="none">
            {sorted.map(a => (
              <AutomationInfoRow key={`journey-selector-row-${a.id}`} automation={a} />
            ))}
          </Stack>
        </Card>
      </Popover>
    </StyledSelector>
  );
};

const StyledInfoRow = styled(Button)`
  > div > h5 {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

const AutomationInfoRow = ({ automation }: { automation: Automation }) => {
  const { theme } = useTheme();
  const navigate = useNavigate();

  const onOpenJourney = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      navigate(AUTOMATION_ROUTES.automation.path.replace(":automationId", automation.id));
    },
    [navigate, automation.id]
  );

  return (
    <StyledInfoRow
      size="small"
      variant="blended"
      onClick={onOpenJourney}
      startAdornment={
        automation.status === "failing" && (
          <Tooltip tooltip="Failing steps">
            <Icon name="warning" color={theme.palette.danger.default} />
          </Tooltip>
        )
      }
    >
      <Stack justifyContent="space-between" gap="small">
        <FittedTitle
          as="div"
          showTooltip
          maxLength={40}
          variant="bold"
          color={theme.palette.foreground.default}
        >
          {automation.title}
        </FittedTitle>
        <JourneyProgress
          hideText
          journeyStatus={automation.status}
          totalStepCount={automation.totalStepCount}
          completedStepCount={automation.completedStepCount}
        />
      </Stack>
    </StyledInfoRow>
  );
};
