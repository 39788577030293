import { Stack, Title } from "@introist/react-foundation/v2";

import { useNavigate } from "react-router-dom";
import { ListRow } from "components/organisms";
import { WorkflowIcon } from "../../workflows";
import { Employee } from "../../employees/hooks/useEmployees";
import { ListView } from "../../../foundation";
import { Automation } from "../hooks/useAutomations";
import { ActiveStatusTag, StatusTag } from "../../journeys";

type EmployeeAutomationListProps = {
  automations?: Automation[];
  employee: Employee;
};

export const AutomationList = ({ automations, employee, ...rest }: EmployeeAutomationListProps) => {
  const navigate = useNavigate();

  return (
    <ListView.List
      data={automations}
      renderRow={a => (
        <ListRow
          key={a.id}
          gridTemplateColumns="1fr auto"
          onClick={() => {
            navigate(`/automations/${a.id}`, { relative: "path" });
          }}
        >
          <Stack>
            <WorkflowIcon />
            <Title variant="bold">{a.title}</Title>
          </Stack>
          {a.stage === "inprogress" ? <ActiveStatusTag /> : <StatusTag status={a.status} />}
        </ListRow>
      )}
      {...rest}
    />
  );
};
