import { IconButton, Tooltip } from "@introist/react-foundation/v2";
import { useCallback } from "react";
import * as xlsx from "xlsx";

export type ExportDataProps = {
  data: any;
  fileName?: string;
  sheetTitle?: string;
};

export const ExportData = ({ data, fileName, sheetTitle }: ExportDataProps) => {
  const exportData = useCallback(async () => {
    const worksheet = xlsx.utils.json_to_sheet(data);
    const workbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(workbook, worksheet, sheetTitle ?? "Data");

    xlsx.writeFile(workbook, fileName ?? "data.xlsx");
  }, [data, fileName, sheetTitle]);

  return (
    <>
      <Tooltip tooltip="Export selected data">
        <IconButton variant="outlined" size="large" icon="file" onClickWithLoading={exportData} />
      </Tooltip>
    </>
  );
};
