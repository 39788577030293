import { DataForm } from "@introist/react-foundation/v2";
import { WorkflowEditorBlock } from "modules/workflows/blocks";
import { EmailContentBlock } from "./blocks/EmailContentBlock";
import { WorkflowStep } from "services/api/WorkflowApi";
import { JourneyStepV2 } from "services/rpc/RpcProvider";

type EmailStepEditorProps = {
  form: DataForm<WorkflowStep> | DataForm<JourneyStepV2>;
  formPrefix?: string;
};

export const EmailStepEditor = ({ form, formPrefix = "stepData." }: EmailStepEditorProps) => {
  return (
    <>
      <WorkflowEditorBlock.EmailAddresses form={form} formKeyPrefix={formPrefix} />
      <EmailContentBlock form={form} formKeyPrefix={formPrefix} />
      <WorkflowEditorBlock.Attachments form={form} />
    </>
  );
};
