import { StepEditorProps } from "../useStepEditor";
import { Stack, TimeRange } from "@introist/react-foundation/v2";
import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { TriggerPicker } from "./TriggerPicker";
import { PopupTimeRangePickerV2 } from "../../../workflows/components/PopupTimeRangePicker/v2";

import { prefixForm } from "../../../../utils/FormUtils";
import { MeetingFlexEditor } from "./MeetingFlexEditor";

export const MeetingSchedule = ({ form, stepDataPath, targetDateTerm }: StepEditorProps) => {
  const prefixedForm = prefixForm(form, stepDataPath);

  return (
    <Stack vertical>
      <StepEditorAccordion.Row label="Date">
        <Stack justifyContent="space-between">
          <TriggerPicker
            value={prefixedForm.get("dateTrigger")}
            onChange={prefixedForm.set("dateTrigger")}
            suffix={targetDateTerm ?? ""}
          />
        </Stack>
      </StepEditorAccordion.Row>
      <StepEditorAccordion.Row label="Time">
        <PopupTimeRangePickerV2
          sameWidthWithReference
          scrollToSelected
          value={{
            start: prefixedForm.get("fromTime"),
            end: prefixedForm.get("toTime")
          }}
          onSelect={(range: TimeRange) => {
            prefixedForm.set("fromTime")(range.start);
            prefixedForm.set("toTime")(range.end);
          }}
          allDayEnabled
        />
      </StepEditorAccordion.Row>

      <StepEditorAccordion.Row label="Find a time">
        <MeetingFlexEditor value={prefixedForm.get("flex")} onChange={prefixedForm.set("flex")} />
      </StepEditorAccordion.Row>
    </Stack>
  );
};
