import { SympaConnectForm } from "./sources/sympahr";
import { ConnectFormProps } from "./views/DataSourceConnectionSetupView/steps/DataSourceConnectionForm";
import { PlanMillConnectForm } from "./sources/planmill";
import { PersonioConnectForm } from "./sources/personio";
import { CsvConnectForm } from "./sources/csv";
import { GoogleDriveCsvConnectForm } from "./sources/drivecsv";
import { HiBobConnectForm } from "./sources/hibob";
import { GoogleDriveSheetConnectForm } from "./sources/drivesheet";
import { GreenhouseConnectForm } from "./sources/greenhouse";
import { BambooHrConnectForm } from "./sources/bamboohr";
import { TeamTailorConnectForm } from "./sources/teamtailor";
import { GoogleFormsConnectForm } from "./sources/googleforms";
import { SeveraConnectForm } from "./sources/severa";
import { EmailDataSourceConnectForm } from "./sources/email";

export type DataSourceSpec = {
  key: string;
  title: string;
  ConnectForm: (props: ConnectFormProps) => JSX.Element;
};

export const DataSources: DataSourceSpec[] = [
  {
    key: "bamboohr",
    title: "BambooHR",
    ConnectForm: BambooHrConnectForm
  },
  {
    key: "hibob",
    title: "Bob",
    ConnectForm: HiBobConnectForm
  },
  {
    key: "csv",
    title: "CSV",
    ConnectForm: CsvConnectForm
  },
  {
    key: "drive_csv",
    title: "Google Drive CSV",
    ConnectForm: GoogleDriveCsvConnectForm
  },
  {
    key: "drivesheet",
    title: "Google Drive Sheet",
    ConnectForm: GoogleDriveSheetConnectForm
  },
  {
    key: "email",
    title: "Email",
    ConnectForm: EmailDataSourceConnectForm
  },
  {
    key: "googleforms",
    title: "Google Forms",
    ConnectForm: GoogleFormsConnectForm
  },
  {
    key: "greenhouse",
    title: "Greenhouse",
    ConnectForm: GreenhouseConnectForm
  },
  {
    key: "teamtailor",
    title: "TeamTailor",
    ConnectForm: TeamTailorConnectForm
  },
  {
    key: "personio",
    title: "Personio",
    ConnectForm: PersonioConnectForm
  },
  {
    key: "planmill",
    title: "Planmill",
    ConnectForm: PlanMillConnectForm
  },
  { key: "severa", title: "Severa", ConnectForm: SeveraConnectForm },
  {
    key: "sympahr",
    title: "Sympa HR",
    ConnectForm: SympaConnectForm
  }
];

export const getDataSourceSpec = (dataSource: string) => {
  const spec = DataSources.find(ds => ds.key === dataSource);
  if (!spec) {
    throw new Error(`No data source ${dataSource}`);
  }
  return spec;
};
