import { EditorHandle, HtmlEditor } from "./HtmlEditor/HtmlEditor";
import { AIAssistant } from "../../modules/AIAssistant/AIAssistant";
import { Stack } from "@introist/react-foundation/v2";
import { useRef } from "react";
import { ContentType, EmailMessageResult } from "modules/AIAssistant/schema";
import { useMaterials } from "../../modules/flows/step-editors/useMaterials";

export type MessageEditorProps = {
  message: string;
  onChange: (message: string) => void;
  variables?: { key: string; title: string }[];
  error?: boolean;
  underline?: boolean;
  contentType?: ContentType;
};

export const MessageEditor = ({
  message,
  onChange,
  error,
  underline,
  contentType
}: MessageEditorProps) => {
  const ref = useRef<EditorHandle | null>(null);
  const { materials } = useMaterials();

  return (
    <Stack vertical gap="xSmall">
      <HtmlEditor
        ref={ref}
        defaultValue={message}
        onChange={onChange}
        materials={materials}
        style={{ maxWidth: "500px" }}
        error={error}
        underline={underline}
        fields
      />
      <AIAssistant
        operation="message"
        contentType={contentType ?? ContentType.EmailMessage}
        template={message}
        onSubmit={result => ref.current?.replaceHTML((result as EmailMessageResult).message)}
      />
    </Stack>
  );
};
