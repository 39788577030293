import { useEffect, useRef } from "react";
import { IntroistLoader, useToast } from "@introist/react-foundation/v2";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { completeAccountConnection, getConnectionAuthorizationUrl } from "./ConnectedAccountsApi";

export const CONNECT_ACCOUNT_REDIRECT_URL_STORAGE_KEY = "rd.strg";

export const ConnectAccountRoute = () => {
  const { provider } = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const mounted = useRef(false);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      getConnectionAuthorizationUrl(provider ?? "")
        .then(url => {
          window.location.replace(url);
        })
        .catch(() => {
          toast.error("Failed to connect account");
          navigate(-1);
        });
    }
  }, [provider, navigate, toast]);

  return (
    <div>
      <IntroistLoader globalCenter />
    </div>
  );
};

export const CompleteConnectAccountRoute = () => {
  const { provider } = useParams();
  const toast = useToast();

  const navigate = useNavigate();
  const { search } = useLocation();

  const mounted = useRef(false);

  const redirectOverrideUrl = localStorage.getItem(CONNECT_ACCOUNT_REDIRECT_URL_STORAGE_KEY);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      const searchParams = new URLSearchParams(search);
      const code = searchParams.get("code");

      completeAccountConnection(provider ?? "", code ?? "")
        .then(() => {
          toast.success("Account connected");

          if (redirectOverrideUrl) {
            navigate(redirectOverrideUrl);
          } else {
            navigate(`/settings/accounts/${provider}`);
          }
        })
        .catch(() => {
          toast.error("Failed to connect account");
          if (redirectOverrideUrl) {
            navigate(redirectOverrideUrl);
          } else {
            navigate(`/settings/accounts/${provider}`);
          }
        })
        .finally(() => {
          localStorage.removeItem(CONNECT_ACCOUNT_REDIRECT_URL_STORAGE_KEY);
        });
    }
  }, [provider, toast, search, redirectOverrideUrl, navigate]);

  return (
    <div>
      <IntroistLoader globalCenter />
    </div>
  );
};
