import { Stack } from "@introist/react-foundation/v2";
import { useState } from "react";
import { EmployeeFieldSelector } from "./modules/employees/fields/EmployeeFieldSelector";
import { EmployeeFieldTag } from "./modules/employees/fields/EmployeeFieldTag";
import { FieldAwareInput } from "./modules/employees/fields/FieldAwareInput";

export const Playground = () => {
  const [value, setValue] = useState<string>(
    "Hello {{firstname}} {{lastname}}, meet your manager {{linkmanager.firstname}} and some"
  );

  return (
    <Stack vertical justifyContent="center" alignItems="center" style={{ minHeight: "100vh" }}>
      <EmployeeFieldSelector onSelect={setValue} />
      {value && <EmployeeFieldTag path={value} />}
      <FieldAwareInput value={value} onChange={setValue} style={{ width: "500px" }} />
    </Stack>
  );
};
