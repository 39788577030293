import {
  Button,
  Card,
  Drawer,
  H3,
  Icon,
  IconName,
  Stack,
  Tag,
  useActions,
  useToast
} from "@introist/react-foundation/v2";
import { ReactNode, useMemo, useState } from "react";
import { Nudge } from "../../useNudges";
import { api, StepTypeWithEventTypeV2 } from "../../../../services/rpc/RpcProvider";
import { TriggerUpdate } from "../../../triggers";
import { RuleViewer } from "../../../../components/organisms/RecursiveExpressionEditor/components/RuleViewer/RuleViewer";
import {
  ExpressionFieldType,
  expressionToConditionGroup,
  RecursiveExpressionEditor
} from "../../../../components/organisms/RecursiveExpressionEditor";
import { ComparisonMode, FullscreenModal } from "../../../../components/organisms";
import { useEmployeeFields } from "../../../employees/hooks/useEmployeeFields";
import { useWorkflowSteps, WorkflowStep } from "../../../flows/hooks/useWorkflowSteps";
import { WorkflowStepCard } from "../../../flows/components/WorkflowStepCard";
import { StepTypeSelector } from "../../../flows/components/StepTypeSelector";
import { WorkflowStepEditorDrawer } from "../../../flows/containers/WorkflowStepEditor/WorkflowStepEditorDrawer";
import { buildInitialStepData } from "../../../../services/api/workflows/StepDataBuilder";
import { Skeletons } from "../../../../components/molecules";

export const NudgeConfig = ({ nudge }: { nudge: Nudge }) => {
  const toast = useToast();
  const { onConfirmAction } = useActions();
  const { fields } = useEmployeeFields();

  const archiveStep = api.workflows.v4.steps.archive.useMutation();

  const { data: trigger, refetch: refetchTrigger } = api.triggers.find.useQuery({
    id: nudge.triggerId
  });
  const { steps, refetch: refetchSteps } = useWorkflowSteps({ workflowId: nudge.workflowId });

  const [stepEditOpen, setStepEditOpen] = useState(false);
  const [stepSelectorOpen, setStepSelectorOpen] = useState(false);
  const [newStepType, setNewStepType] = useState<string | undefined>(undefined);

  const [triggerEditOpen, setTriggerEditOpen] = useState(false);

  const [triggerUpdates, setTriggerUpdates] = useState<TriggerUpdate["updates"]>({});

  const updateTriggerApi = api.triggers.update.useMutation({});

  const updateTrigger = async (updates: TriggerUpdate["updates"]) => {
    await updateTriggerApi.mutateAsync(
      {
        id: trigger!.id,
        updates
      },
      {
        onSuccess: async () => {
          await refetchTrigger();
          setTriggerUpdates({});
          setTriggerEditOpen(false);
        }
      }
    );
  };

  const step = useMemo(() => {
    if (newStepType) {
      return buildInitialStepData({
        extendedStepType: newStepType as StepTypeWithEventTypeV2,
        params: {}
      });
    }
    if (!steps || steps.length === 0) return {} as WorkflowStep;
    return steps[0];
  }, [newStepType, steps]);

  const onArchiveStep = onConfirmAction(
    async () => {
      await archiveStep
        .mutateAsync({ id: steps![0].id })
        .then(async () => {
          await refetchSteps();
        })
        .catch(() => {
          toast.error("Failed to remove step");
        });
    },
    {
      title: "Remove nudge action",
      description: "Content in action will be permanently lost and can not be restored."
    }
  );

  const conditions = useMemo(() => {
    if (!trigger) return { id: "none", mode: "and" as ComparisonMode, conditions: [] };
    return expressionToConditionGroup(triggerUpdates.triggerRule ?? trigger.triggerRule);
  }, [triggerUpdates, trigger]);

  if (!trigger) return <Skeletons.Block height={180} width={700} />;

  return (
    <Stack vertical style={{ marginTop: "16px" }}>
      <Stack vertical gap="xLarge" style={{ maxWidth: "900px", width: "900px", margin: "auto" }}>
        <Stack style={{ alignItems: "flex-start" }}>
          <ConfigCard title="When" icon="timeCheck">
            {conditions.conditions.length > 0 && (
              <div style={{ cursor: "pointer" }} onClick={() => setTriggerEditOpen(true)}>
                <RuleViewer
                  conditionGroup={conditions}
                  fields={(fields ?? []).map(f => ({
                    variable: f.key,
                    name: f.title,
                    type: f.type as ExpressionFieldType
                  }))}
                  collapsedCount={3}
                />
              </div>
            )}
            {conditions.conditions.length === 0 && (
              <Card variant="blended" style={{ padding: `56px 0` }}>
                <Stack vertical alignItems="center">
                  <Button variant="outlined" onClick={() => setTriggerEditOpen(true)}>
                    Set up rules
                  </Button>
                </Stack>
              </Card>
            )}
          </ConfigCard>
          <div style={{ alignSelf: "center" }}>
            <Icon name="arrowRight" />
          </div>
          <ConfigCard title="Then" icon="targetAfter">
            {steps && steps.length > 0 && (
              <WorkflowStepCard
                step={steps[0]}
                onClick={() => setStepEditOpen(true)}
                onRemove={onArchiveStep}
              />
            )}
            {steps && steps.length === 0 && (
              <Card variant="blended" style={{ padding: `56px 0` }}>
                <Stack vertical alignItems="center">
                  <Button variant="outlined" onClick={() => setStepSelectorOpen(true)}>
                    Set up action
                  </Button>
                </Stack>
              </Card>
            )}
          </ConfigCard>
        </Stack>
      </Stack>

      <FullscreenModal.Default
        header={{
          title: "Edit match rules",
          bottomBorder: true,
          onClose: () => setTriggerEditOpen(false)
        }}
        footer={{
          children: (
            <Stack justifyContent="flex-end">
              <Button
                variant="outlined"
                onClick={() => {
                  setTriggerUpdates({});
                  setTriggerEditOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                onClickWithLoading={async () => {
                  await updateTrigger(triggerUpdates).catch(() => {
                    toast.error("Failed to update rules");
                  });
                }}
              >
                Save
              </Button>
            </Stack>
          ),
          topBorder: true
        }}
        open={triggerEditOpen}
        onClose={() => setTriggerEditOpen(false)}
      >
        <RecursiveExpressionEditor
          expression={triggerUpdates.triggerRule ?? trigger.triggerRule ?? { and: [] }}
          fields={(fields ?? []).map(f => ({
            variable: f.key,
            name: f.title,
            type: f.type as ExpressionFieldType
          }))}
          onChange={triggerRule => setTriggerUpdates({ ...triggerUpdates, triggerRule })}
          config={{
            attributeListTitle: "Create rule based on...",
            placeholderConditionCardText: "Create rule based on...",
            placeholderConditionCardDescription: (
              <Stack gap="small">
                <span>Start by selecting the employee field on the right</span>
                <H3>👉</H3>
              </Stack>
            )
          }}
        />
      </FullscreenModal.Default>
      <Drawer width="540px" open={stepSelectorOpen} onClose={() => setStepSelectorOpen(false)}>
        <StepTypeSelector
          title="Select action type"
          onClose={() => setStepSelectorOpen(false)}
          onSelect={stepType => {
            setStepSelectorOpen(false);
            setNewStepType(stepType);
            setStepEditOpen(true);
          }}
        />
      </Drawer>
      <WorkflowStepEditorDrawer
        open={stepEditOpen}
        onClose={() => {
          setNewStepType(undefined);
          setStepEditOpen(false);
        }}
        workflowId={nudge.workflowId}
        step={step}
        mode="nudge"
      />
    </Stack>
  );
};

const ConfigCard = ({
  title,
  icon,
  children
}: {
  title: string;
  icon: IconName;
  children: ReactNode;
}) => (
  <div style={{ flex: 1 }}>
    <Stack vertical>
      <Tag startIcon={icon}>{title}</Tag>
      {children}
    </Stack>
  </div>
);
