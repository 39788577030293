import { ComponentPropsWithoutRef } from "react";
import styled from "styled-components";
import { Layout, Tag, Title } from "@introist/react-foundation/v2";

import { JourneyOverview } from "services/rpc/RpcProvider";
import { ProgressBar } from "components/molecules";

export type JourneyProgressProps = ComponentPropsWithoutRef<"div"> & {
  journeyStatus?: JourneyOverview["status"];
  completedStepCount: number;
  totalStepCount: number;
  hideText?: boolean;
  small?: boolean;
};

const StyledProgressBar = styled(ProgressBar)`
  height: 3px;
  width: 2rem;
  border-radius: 3px;

  > span {
    background-color: var(--palette-success-default);
  }
`;

export const JourneyProgress = ({
  journeyStatus,
  completedStepCount,
  totalStepCount,
  hideText,
  small,
  ...props
}: JourneyProgressProps) => {
  if (journeyStatus === "draft") {
    return <Tag colorVariant="outlined">Draft</Tag>;
  }
  if (journeyStatus === "canceled") {
    return <Tag>Canceled</Tag>;
  }
  if (journeyStatus === "completed") {
    return <Tag colorVariant="success">Completed</Tag>;
  }

  if (!journeyStatus || totalStepCount === 0) {
    return null;
  }

  return (
    <Layout.Group gap="small" {...props}>
      {!hideText && (
        <Title small={small} variant="bold">
          {completedStepCount} of {totalStepCount}
        </Title>
      )}
      <StyledProgressBar value={completedStepCount} total={totalStepCount} />
    </Layout.Group>
  );
};
