import {
  ActionMenu,
  Button,
  CircularLoader,
  H4,
  Icon,
  Layout,
  Option,
  Title,
  Tooltip,
  useActions,
  useTheme,
  useToast,
  Stack,
  P4
} from "@introist/react-foundation/v2";
import { List, ListEmptyState, ListRow, ListSection } from "components/organisms";
import { useNavigate } from "react-router-dom";
import { api, EmployeeDataSource } from "services/rpc/RpcProvider";
import { DataSourceIcon } from "../../components/DataSourceIcon";
import { RoundTag } from "../../../../components/RoundTag";
import _ from "lodash";
import moment from "moment";
import styled from "styled-components";

type ConnectedDataSourcesListProps = {
  onAddDataSource: VoidFunction;
};

export const useDataSourceActions = () => {
  const navigate = useNavigate();
  const { theme } = useTheme();
  const toast = useToast();
  const { mutateAsync: deleteDataSource } = api.employees.dataSources.remove.useMutation({});
  const { mutateAsync: syncDataSourceConnection } = api.employees.dataSources.sync.useMutation({});
  const { mutateAsync: updateDataSource } = api.employees.dataSources.update.useMutation({});
  const { refetch } = api.employees.dataSources.list.useQuery({});
  const { onConfirmAction } = useActions();

  const getActions = (dataSource: EmployeeDataSource): Option[] => {
    const actions: Option[] = [];

    if (dataSource.active) {
      actions.push({
        key: "deactivate-data-source-connection",
        title: "Stop auto-sync",
        startAdornmentIcon: "pause",
        onClick: async () => {
          await updateDataSource({ dataSourceId: dataSource.id, updates: { active: false } });
          await refetch();
          toast.info("Data source inactivated");
        }
      });
    } else {
      actions.push({
        key: "activate-data-source-connection",
        title: "Activate auto-sync",
        startAdornmentIcon: "playCircle",
        onClick: async () => {
          await updateDataSource({ dataSourceId: dataSource.id, updates: { active: true } });
          await refetch();
          toast.success("Data source activated");
        }
      });
    }

    actions.push(
      ...[
        {
          key: "edit-data-source-connection",
          title: "Edit connection",
          startAdornmentIcon: "pencil",
          onClick: () => navigate(`#edit=${dataSource.id}`, { relative: "path" })
        } as Option,
        {
          key: "edit-data-source-filtering",
          title: "Edit filter",
          startAdornmentIcon: "filter",
          onClick: () => navigate(`#filter=${dataSource.id}`, { relative: "path" })
        } as Option,
        {
          key: "edit-data-source-create-condition",
          title: "Edit create condition",
          startAdornmentIcon: "filter",
          onClick: () => navigate(`#createcondition=${dataSource.id}`, { relative: "path" })
        } as Option,
        {
          key: "sync-data-source-connection",
          title: "Sync now",
          startAdornmentIcon: "collapseDrawer",
          onClick: async () => {
            await syncDataSourceConnection(
              { dataSourceId: dataSource.id },
              {
                onSuccess: () => {
                  toast.success("Sync started, it might take a few minutes to complete");
                  refetch();
                },
                onError: () => toast.error("Starting sync failed")
              }
            );
          }
        } as Option,
        {
          key: "params",
          title: "Show params",
          startAdornmentIcon: "key",
          onClick: () => navigate(`#params=${dataSource.id}`, { relative: "path" })
        } as Option,
        {
          key: "delete-data-source",
          title: "Delete",
          startAdornment: <Icon name="trash" color={theme.palette.danger.default} />,
          titleStyles: {
            color: theme.palette.danger.default
          },
          onClick: onConfirmAction(
            async () => {
              await deleteDataSource({ dataSourceId: dataSource.id });
              await refetch();
            },
            {
              title: "Delete data source",
              description: "Are you sure you want to delete this data source?",
              confirmTitle: "Delete"
            }
          )
        } as Option
      ]
    );

    return actions;
  };

  return { getActions };
};

const StyledP4 = styled(P4)`
  font-style: italic;
  color: var(--palette-foreground-subdued);
`;

export const ConnectedDataSourcesList = ({
  onAddDataSource,
  ...rest
}: ConnectedDataSourcesListProps) => {
  const { theme } = useTheme();
  const navigate = useNavigate();

  const { data: dataSources, isLoading } = api.employees.dataSources.list.useQuery({});
  const { getActions } = useDataSourceActions();

  return (
    <ListSection
      {...rest}
      stretchContent
      title="Data Sources"
      endActions={
        <Button startIcon="plus" size="small" variant="outlined" onClick={onAddDataSource}>
          Connect Data Source
        </Button>
      }
    >
      {isLoading && <CircularLoader fillParent colorVariant="primary" style={{ flexGrow: 1 }} />}
      {!isLoading && dataSources && dataSources.length === 0 && (
        <ListEmptyState>
          <ListEmptyState.Title>No connected data sources</ListEmptyState.Title>
          <Title style={{ textAlign: "center" }}>
            Connect a data source to start synchronizing employee information
          </Title>
          <ListEmptyState.Buttons>
            <Button startIcon="plus" onClick={onAddDataSource}>
              Connect data source
            </Button>
          </ListEmptyState.Buttons>
        </ListEmptyState>
      )}
      {!isLoading && dataSources && dataSources.length > 0 && (
        <List>
          {dataSources.map(dataSource => (
            <ListRow
              key={dataSource.id}
              gridTemplateColumns="1fr 100px"
              onClick={() => navigate(`/datasources/${dataSource.id}`)}
            >
              <Layout.Group gap="large">
                <DataSourceIcon dataSource={dataSource.sourceType} />
                <Stack gap="small">
                  <H4>{dataSource.name}</H4>
                  {!!dataSource.lastSync && (
                    <StyledP4>
                      {`Last synced at ${moment(dataSource.lastSync).format("DD.MM.YYYY HH:mm")}`}
                    </StyledP4>
                  )}
                </Stack>
              </Layout.Group>

              <Layout.Group
                justifyContent="flex-end"
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                {dataSource.active && (
                  <Tooltip tooltip={`Auto-sync active`}>
                    <RoundTag
                      startAdornment={
                        <Icon name="recurring" color={theme.palette.success.default} />
                      }
                    ></RoundTag>
                  </Tooltip>
                )}
                {!_.isEqual(dataSource.filterCondition, {}) && (
                  <Tooltip tooltip={`Filter active`}>
                    <RoundTag
                      startAdornment={<Icon name="filter" color={theme.palette.success.default} />}
                      onClick={() => navigate(`#filter=${dataSource.id}`, { relative: "path" })}
                    ></RoundTag>
                  </Tooltip>
                )}
                {dataSource.mappedFieldCount ? (
                  <Tooltip tooltip={`${dataSource.mappedFieldCount} mapped fields`}>
                    <RoundTag
                      startAdornment={
                        <Icon name="arrowsLeftRight" color={theme.palette.success.default} />
                      }
                    >
                      <Title variant="bold">{dataSource.mappedFieldCount}</Title>
                    </RoundTag>
                  </Tooltip>
                ) : null}

                <ActionMenu options={getActions(dataSource)} />
              </Layout.Group>
            </ListRow>
          ))}
        </List>
      )}
    </ListSection>
  );
};
