import { useMemo } from "react";
import { Field, InfoCard, Layout, Input, Select } from "@introist/react-foundation/v2";

import { HtmlEditor, ReadOnlyEmailMessage } from "components/organisms";

import { Block } from "components/atoms";
import { StepEditorAccordion } from "modules/workflows/routes/WorkflowEditor/StepEditorAccordion";

import { useEmailSenderApi } from "services/api/Senders";

import { PrefixedForm } from "utils/FormUtils";

type InstructionEmailEditorProps = {
  prefixedForm: PrefixedForm;
  readOnly?: boolean;
};

export const InstructionEmailEditor = ({ prefixedForm, readOnly }: InstructionEmailEditorProps) => {
  const senderApi = useEmailSenderApi();
  const { data: senderData } = senderApi.list({ filterCalendarOnlySenders: true }).query;

  const senders = useMemo(() => {
    const senders = [{ key: "default", title: "Introist" }];
    if (senderData) {
      senderData.forEach(sender => senders.push({ key: sender.id, title: sender.name }));
    }
    return senders;
  }, [senderData]);

  const sendInstructions = useMemo(() => prefixedForm.get("sendInstructions"), [prefixedForm]);

  return (
    <Block blended compact>
      <StepEditorAccordion title="Initial login information" defaultOpen={false}>
        <Layout.Group vertical gap="xLarge">
          <InfoCard
            hideIcon
            colorVariant="primary"
            title={
              readOnly
                ? `Initial login information was sent to Employee using the following message.`
                : `The initial login information, including an automatically generated password, will be sent to the employee using the email template provided. Please ensure that the variables {{username}} and {{password}} are included in the message.`
            }
          />

          {sendInstructions && (
            <>
              <Field title="From">
                <Select
                  options={senders}
                  size="small"
                  value={prefixedForm.get("instructionMessageTemplate.from")}
                  onSelect={opt => {
                    prefixedForm.set("instructionMessageTemplate.from")(opt.key);
                  }}
                  style={{ width: "100%" }}
                  readOnly={readOnly}
                />
              </Field>
              <Field title="To">
                <Select
                  readOnly
                  size="small"
                  options={[]}
                  value={
                    readOnly ? prefixedForm.get("instructionMessage.to") : "Employees private email"
                  }
                  style={{ width: "100%" }}
                />
              </Field>
              {readOnly && (
                <ReadOnlyEmailMessage
                  subject={prefixedForm.get("instructionMessage.subject")}
                  message={prefixedForm.get("instructionMessage.message")}
                />
              )}

              {!readOnly && (
                <>
                  <Field title="Subject">
                    <Input
                      size="small"
                      value={prefixedForm.get("instructionMessageTemplate.subject")}
                      onChange={prefixedForm.set("instructionMessageTemplate.subject")}
                    />
                  </Field>
                  <Field title="Message">
                    <HtmlEditor
                      defaultValue={prefixedForm.get("instructionMessageTemplate.message")}
                      onChange={prefixedForm.set("instructionMessageTemplate.message")}
                    />
                  </Field>
                </>
              )}
            </>
          )}
        </Layout.Group>
      </StepEditorAccordion>
    </Block>
  );
};
