import styled from "styled-components";
import { TooltipData } from "./hooks";
import { InfoCard, Stack, Title } from "@introist/react-foundation/v2";
import useMeasure from "react-use-measure";
import { DateFormats } from "utils/dates";
import { formatDate } from "utils/DatesUtils";
import { JourneyProgress } from "modules/journeys";
import { IconPickerIcon } from "../../../../../components/organisms";

const StyledJourneysTimelineTooltip = styled.div`
  background-color: white;
  box-shadow: var(--shadow-default);
  border-radius: var(--rounding-medium);
  position: absolute;
  pointer-events: none;
  padding: 1rem;
  top: 0;
  opacity: 0;
`;

const Separator = styled.span`
  display: inline-flex;
  width: 1px;
  height: 2rem;
  background-color: var(--palette-border-subdued);
`;

export const JourneysTimelineTooltip = ({ visible, x, y, entry, ...props }: TooltipData) => {
  const [tooltipRef, tooltipRect] = useMeasure();

  const formattedStart = entry?.firstStepAt
    ? formatDate(new Date(entry.firstStepAt).toString(), DateFormats.date.withMonth.long)
    : null;

  const formattedEnd = entry?.lastStepAt
    ? formatDate(new Date(entry.lastStepAt).toString(), DateFormats.date.withMonth.long)
    : null;

  return (
    <StyledJourneysTimelineTooltip
      {...props}
      ref={tooltipRef}
      style={{
        opacity: visible ? 1 : 0,
        transform: `translate(${x + 16 - tooltipRect.width / 2}px, ${y + 4}px)`
      }}
    >
      <Stack vertical>
        <Stack gap="medium">
          <IconPickerIcon icon="workflows" fallbackIcon="workflows" />
          <Stack vertical gap="none">
            <Title small>{entry?.workflow.name}</Title>
            <Title variant="bold">{entry?.employeeName}</Title>
          </Stack>
        </Stack>
        <Stack>
          <Stack gap="none" vertical>
            <Title small>First step</Title>
            <Title small variant="bold">
              {formattedStart}
            </Title>
          </Stack>
          <Separator />
          <Stack gap="none" vertical>
            <Title small>Last step</Title>
            <Title small variant="bold">
              {formattedEnd}
            </Title>
          </Stack>
          <Separator />
          {entry && (
            <Stack vertical gap="none">
              <Title small>Progress</Title>

              <JourneyProgress
                small
                journeyStatus={entry.status}
                totalStepCount={entry.totalStepCount}
                completedStepCount={entry.completedStepCount}
              />
            </Stack>
          )}
        </Stack>
        {entry?.status === "failing" && (
          <InfoCard
            colorVariant="danger"
            icon="warning"
            title="Some steps are failing"
            style={{ padding: 8 }}
          ></InfoCard>
        )}
      </Stack>
    </StyledJourneysTimelineTooltip>
  );
};
