import { useMemo, useState } from "react";
import { Button, Layout, Stack } from "@introist/react-foundation/v2";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import { appRoutes } from "AppRoutes";

import { ListView, TopBarRoute } from "components/templates";
import { Skeletons, TabBar, TabCard } from "components/molecules";
import { useStoredState } from "hooks/use-storage-state";
import { StartAutomationModal } from "../../automations/containers/StartAutomationModal";
import { Empty, Loading } from "../../../foundation";
import { useEmployee } from "../hooks/useEmployees";
import { EmployeeProfile } from "../containers/EmployeeProfile";
import { EmployeeHeader } from "../containers/EmployeeProfile/EmployeeHeader";
import { AutomationStatusFilter } from "../../automations/components/AutomationsFilter";
import { useAutomations } from "../../automations/hooks/useAutomations";
import { AutomationList } from "../../automations/components/AutomationList";
import { AutomationGrid } from "../../automations/components/AutomationGrid";
import { isEmpty } from "lodash";

export const EmployeeRoute = () => {
  const { employeeId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [listViewTab, setListViewTab] = useStoredState<"list" | "grid">(
    "intr.empl.wfs.view",
    "grid"
  );

  const [automationFilter, setAutomationFilter] = useState<string[]>([]);

  const onListViewTabChange = (tab: string) => {
    setListViewTab(tab as "list" | "grid");
  };

  const [currentTab, setCurrentTab] = useState(
    location.hash === "#fields" ? "profile" : "automations"
  );

  const [startWorkflowOpen, setStartWorkflowOpen] = useState(false);

  const [search, setSearch] = useState("");

  const { employee } = useEmployee({ id: employeeId!, fields: [] });

  const { automations, refetch } = useAutomations({
    stages: ["draft", "inprogress", "ended"],
    employeeId
  });

  const filteredAutomations = useMemo(() => {
    if (!automations) return undefined;
    const stageFiltered = automations.filter(a => automationFilter.includes(a.stage!));
    return stageFiltered.filter(
      a => isEmpty(search) || a.title.toLowerCase().includes(search.toLowerCase())
    );
  }, [automations, automationFilter, search]);

  return (
    <TopBarRoute
      medium
      paths={[
        { key: "employees", title: "Employees", onClick: () => navigate(appRoutes.employees.path) },
        { key: "employee", title: employee?.name ?? "" }
      ]}
    >
      <TabCard
        currentTab={currentTab}
        tabs={[
          {
            id: "profile",
            title: "Profile"
          },
          {
            id: "automations",
            title: "Automations"
          }
        ]}
        onChange={tab => {
          if (tab === "profile") {
            navigate("#fields", { relative: "path" });
          } else {
            navigate("", { relative: "path" });
          }
          setCurrentTab(tab);
        }}
      >
        {employee && (
          <Layout.Group vertical gap="xxLarge" style={{ flex: 1 }}>
            {currentTab === "automations" && <EmployeeHeader employee={employee} />}

            {currentTab === "automations" && (
              <Stack vertical style={{ flex: 1 }}>
                <ListView.Header
                  title="Automations"
                  count={filteredAutomations?.length}
                  left={
                    <TabBar
                      small
                      tabs={[
                        { id: "list", iconName: "list" },
                        {
                          id: "grid",
                          iconName: "grid"
                        }
                      ]}
                      activeTab={listViewTab}
                      onTabChange={onListViewTabChange}
                    />
                  }
                  right={
                    <AutomationStatusFilter
                      setFilter={setAutomationFilter}
                      automations={automations}
                    />
                  }
                  create={
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => setStartWorkflowOpen(true)}
                      startIcon="plusSmall"
                    >
                      Enroll
                    </Button>
                  }
                  search={{
                    setSearch,
                    search
                  }}
                />
                <Loading
                  data={filteredAutomations}
                  loading={listViewTab === "list" ? <Skeletons.List /> : <Skeletons.Grid />}
                  empty={<Empty title="No automations" icon="workflows" dark border={false} />}
                >
                  <>
                    {listViewTab === "list" && (
                      <AutomationList employee={employee} automations={filteredAutomations} />
                    )}
                    {listViewTab === "grid" && <AutomationGrid automations={filteredAutomations} />}
                  </>
                </Loading>
              </Stack>
            )}
            {currentTab === "profile" && <EmployeeProfile id={employee.id!} />}
          </Layout.Group>
        )}
        <Outlet />
      </TabCard>

      <StartAutomationModal
        onStart={async () => {
          await refetch();
        }}
        onClose={() => setStartWorkflowOpen(false)}
        open={startWorkflowOpen}
        employeeId={employeeId!}
      />
    </TopBarRoute>
  );
};
