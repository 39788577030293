import { Layout, IconName, CircularLoader, Icon } from "@introist/react-foundation/v2";
import { AccountsActions } from "./AccountsActions";
import { AccountsHeader } from "./AccountsHeader";
import { AccountsList } from "./AccountsList";
import styled from "styled-components";
import { ConnectedAccount } from "services/rpc/RpcProvider";
import { ListSection } from "components/organisms";

interface Props {
  accounts: ConnectedAccount[];
  iconName: IconName;
  header: string;
  title: string;
  description: string;
  provider: string;
  disableConnectionLink?: boolean;
  isLoading?: boolean;
}

const Divider = styled.div`
  border-top: 1px solid var(--palette-border-subdued);
  width: 100%;
`;

export const AccountsRoot = ({
  accounts,
  iconName,
  header,
  title,
  description,
  provider,
  disableConnectionLink,
  isLoading = false
}: Props) => {
  return (
    <Layout.Group vertical gap="xxLarge">
      <AccountsHeader iconName={iconName} title={header} description={description} />
      <Divider />
      {isLoading ? (
        <Layout.Group justifyContent="center">
          <CircularLoader />
        </Layout.Group>
      ) : (
        <ListSection
          title={
            <Layout.Group>
              <Icon name={iconName} />
              <span>{title}</span>
            </Layout.Group>
          }
          endActions={
            <AccountsActions provider={provider} disableConnectionLink={disableConnectionLink} />
          }
        >
          <AccountsList accounts={accounts} />
        </ListSection>
      )}
    </Layout.Group>
  );
};
