import { useTheme } from "@introist/react-foundation/v2";
import styled from "styled-components";

type Props = {
  y1: number;
  y2: number;
  x1: number;
  x2: number;
  opacity: number;
  date: string | null;
};

const Text = styled.text`
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  fill: var(--palette-foreground-subdued);
  text-anchor: end;
  pointer-events: none;
`;

export const FocusLine = ({ date, opacity, ...props }: Props) => {
  const { theme } = useTheme();

  return (
    <g opacity={opacity}>
      <line
        {...props}
        strokeWidth={1.5}
        stroke={theme.palette.primary.default}
        style={{ pointerEvents: "none" }}
      />

      <Text
        x={props.x2 - 6} // Position text at the end of the line
        y={props.y2 - 6} // Adjust as needed for alignment
        fill={theme.palette.foreground.subdued}
      >
        {date || "Unknown date"}
      </Text>
    </g>
  );
};
