import { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import sortBy from "lodash.sortby";

import { DataForm, Layout, TextButton } from "@introist/react-foundation/v2";
import { useEmailSenders } from "hooks/use-email-senders";
import { Block } from "components/atoms";

import { WorkflowStep } from "services/api/WorkflowApi";
import { JourneyStepV2 } from "services/rpc/RpcProvider";

import { useEmployeeAttributeApi } from "modules/employees/api/EmployeeAttributeApi";
import { StepEditorAccordion } from "modules/workflows/routes/WorkflowEditor/StepEditorAccordion";

import { EmailAddressMultiSelect } from "../../../components/EmailAddressMultiSelect";
import { EmailAddressSingleSelect } from "../../../components/EmailAddressSingleSelect";
import { TOption } from "../../../components/PortalOptionList/types";

type EmailAddressesBlockProps = {
  form: DataForm<WorkflowStep> | DataForm<JourneyStepV2>;
  formKeyPrefix?: string;
  readOnly?: boolean;
  journeyAttributes?: { [index: string]: string };
};

const CcAddWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`;

const SenderSelectWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: var(--spacing-small);
`;

const StyledTextButton = styled(TextButton)`
  padding-top: var(--spacing-medium);
`;

const useEmailAddressesAttributeOptions = (
  formValues: string[],

  journeyAttributes?: { [index: string]: string }
) => {
  const { data: employeeAttributes, isLoading } = useEmployeeAttributeApi().list({
    includeHidden: false
  }).query;

  const options: TOption[] = useMemo(() => {
    if (!employeeAttributes) return [];

    const options = employeeAttributes
      .filter(prop => prop.type === "email")
      .map(prop => {
        return {
          key: prop.variable,
          title: prop.name,
          description: journeyAttributes ? journeyAttributes[prop.variable] : undefined
        };
      });

    const optionKeys = options.map(option => option.key);
    const customValues = formValues.filter(value => !optionKeys.includes(value));

    const customOptions = customValues.map(value => ({
      key: value,
      title: value,
      description: value
    }));

    const allOptions = [...options, ...customOptions];

    return sortBy(allOptions, "title");
  }, [employeeAttributes, journeyAttributes, formValues]);

  const initialSelectedOptions = useMemo(() => {
    return options.filter(({ key }) => formValues.includes(key));
  }, [options, formValues]);

  return { options, initialSelectedOptions, isLoading };
};

export const EmailStepAddressesBlock = ({
  form,
  formKeyPrefix,
  readOnly,
  journeyAttributes,
  ...rest
}: EmailAddressesBlockProps) => {
  const [replyToActive, setReplyToActive] = useState(false);
  const [ccActive, setCcActive] = useState(false);
  const [bccActive, setBccActive] = useState(false);

  const getFullKey = useCallback((key: string) => `${formKeyPrefix || ""}${key}`, [formKeyPrefix]);

  const getArray = (formKey: string): string[] => {
    const value = form.get(formKey);
    if (!value) return [];
    if (Array.isArray(value)) return value;

    return value.startsWith("[") ? JSON.parse(value) : [value];
  };

  const { senders, isLoading: sendersLoading } = useEmailSenders();

  const initialSenderOptions: TOption[] = useMemo(() => {
    return senders.map(sender => ({
      key: sender.id,
      title: sender.name,
      description: sender.email
    }));
  }, [senders]);

  const initialSelectedSenderOption = useMemo(() => {
    const senderId = form.get(getFullKey("emailSenderId"));
    if (!senderId) return;

    const option = initialSenderOptions.find(({ key }) => key === senderId);
    return option;
  }, [form, initialSenderOptions, getFullKey]);

  const {
    options: toOptions,
    initialSelectedOptions: initialSelectedToOptions,
    isLoading: toOptionsLoading
  } = useEmailAddressesAttributeOptions(
    getArray(getFullKey("to")).filter(a => !!a),
    journeyAttributes
  );

  const {
    options: replyToOptions,
    initialSelectedOptions: initialSelectedReplyToOptions,
    isLoading: replyToOptionsLoading
  } = useEmailAddressesAttributeOptions(
    getArray(getFullKey("replyTo")).filter(a => !!a),
    journeyAttributes
  );

  const {
    options: ccOptions,
    initialSelectedOptions: initialSelectedCcOptions,
    isLoading: ccOptionsLoading
  } = useEmailAddressesAttributeOptions(
    getArray(getFullKey("cc")).filter(a => !!a),
    journeyAttributes
  );

  const {
    options: bccOptions,
    initialSelectedOptions: initialSelectedBccOptions,
    isLoading: bccOptionsLoading
  } = useEmailAddressesAttributeOptions(
    getArray(getFullKey("bcc")).filter(a => !!a),
    journeyAttributes
  );

  const hasReplyToFormValue = !!form.get(getFullKey("replyTo"));
  const showReplyTo = hasReplyToFormValue || replyToActive;
  const renderCc = ccActive || getArray(getFullKey("cc")).length > 0;
  const showReplyToButton = !showReplyTo && !readOnly;
  const showCcButton = !renderCc && !readOnly;
  const renderBcc = bccActive || getArray(getFullKey("bcc")).length > 0;
  const showBccButton = !renderBcc && !readOnly;

  const loading =
    sendersLoading ||
    toOptionsLoading ||
    replyToOptionsLoading ||
    ccOptionsLoading ||
    bccOptionsLoading;
  const showAccordionError =
    form.isError(getFullKey("emailSenderId")) ||
    form.isError(getFullKey("replyTo")) ||
    form.isError(getFullKey("to")) ||
    form.isError(getFullKey("cc")) ||
    form.isError(getFullKey("bcc"));

  if (loading) {
    return null;
  }

  return (
    <Block blended compact {...rest}>
      <StepEditorAccordion
        title="Sender & Receivers"
        error={showAccordionError ? "Invalid fields" : undefined}
      >
        <Layout.Group vertical alignItems="stretch" gap="large">
          <StepEditorAccordion.Row label="From">
            <SenderSelectWrapper>
              <EmailAddressSingleSelect
                readOnly={readOnly}
                initialOptions={initialSenderOptions}
                initialSelectedOption={initialSelectedSenderOption}
                addButtonText="Add sender"
                error={form.error(getFullKey("emailSenderId"))}
                onChange={selectedId => {
                  form.set(getFullKey("emailSenderId"))(selectedId || null);
                }}
              />

              {showReplyToButton && (
                <TextButton onClick={() => setReplyToActive(true)}>Reply to</TextButton>
              )}
            </SenderSelectWrapper>
          </StepEditorAccordion.Row>

          {showReplyTo && (
            <StepEditorAccordion.Row label="Reply to">
              <EmailAddressSingleSelect
                readOnly={readOnly}
                initialOptions={replyToOptions}
                initialSelectedOption={initialSelectedReplyToOptions[0]}
                addButtonText="Add reply to"
                error={form.error(getFullKey("replyTo"))}
                onChange={selectedId => {
                  form.set(getFullKey("replyTo"))(selectedId || null);
                }}
                onEnter={form.set(getFullKey("replyTo"))}
              />
            </StepEditorAccordion.Row>
          )}

          <StepEditorAccordion.MultiOptionRow label="To">
            <EmailAddressMultiSelect
              readOnly={readOnly}
              initialOptions={toOptions}
              initialSelectedOptions={initialSelectedToOptions}
              addButtonText="Add receiver"
              error={form.error(getFullKey("to"))}
              onChange={selectedIds => {
                form.set(getFullKey("to"))(selectedIds);
              }}
              onEnter={form.set(getFullKey("to"))}
            />
            <CcAddWrapper>
              {showCcButton && (
                <StyledTextButton onClick={() => setCcActive(true)}>Cc</StyledTextButton>
              )}
              {showBccButton && (
                <StyledTextButton onClick={() => setBccActive(true)}>Bcc</StyledTextButton>
              )}
            </CcAddWrapper>
          </StepEditorAccordion.MultiOptionRow>
          {renderCc && (
            <StepEditorAccordion.MultiOptionRow label="Cc">
              <EmailAddressMultiSelect
                readOnly={readOnly}
                initialOptions={ccOptions}
                initialSelectedOptions={initialSelectedCcOptions}
                addButtonText="Add cc"
                onChange={form.set(getFullKey("cc"))}
                onEnter={form.set(getFullKey("cc"))}
              />
            </StepEditorAccordion.MultiOptionRow>
          )}
          {renderBcc && (
            <StepEditorAccordion.MultiOptionRow label="Bcc">
              <EmailAddressMultiSelect
                readOnly={readOnly}
                initialOptions={bccOptions}
                initialSelectedOptions={initialSelectedBccOptions}
                addButtonText="Add bcc"
                onChange={form.set(getFullKey("bcc"))}
                onEnter={form.set(getFullKey("bcc"))}
              />
            </StepEditorAccordion.MultiOptionRow>
          )}
        </Layout.Group>
      </StepEditorAccordion>
    </Block>
  );
};
