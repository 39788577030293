import { useToast } from "@introist/react-foundation/v2";
import { api } from "services/rpc/RpcProvider";

export const useComputedField = (fieldVariable?: string) => {
  const toast = useToast();
  const computedField = api.employees.fields.computed.find.useQuery({
    field: fieldVariable || ""
  });

  const testMutation = api.employees.fields.computed.test.useMutation({});
  const updateMutation = api.employees.fields.computed.update.useMutation({});
  const updateForAllEmployeesMutation = api.employees.fields.computed.evaluate.useMutation({});
  const deleteMutation = api.employees.fields.computed.delete.useMutation({});

  const updateTransform = async ({
    code,
    fieldVariable,
    onSuccess
  }: {
    code: string;
    fieldVariable: string;
    onSuccess?: (value?: string | null) => void;
  }) => {
    await updateMutation.mutateAsync(
      {
        code,
        field: fieldVariable
      },
      {
        onError: () => toast.error("Failed to update transform"),
        onSuccess: () => {
          computedField.refetch();
          toast.success("Transform updated");
          onSuccess && onSuccess();
        }
      }
    );
  };

  const updateForAllEmployees = async ({
    fieldVariable,
    onSuccess
  }: {
    fieldVariable: string;
    onSuccess?: () => void;
  }) => {
    await updateForAllEmployeesMutation.mutateAsync(
      { field: fieldVariable },
      {
        onError: () => toast.error("Failed to update transform for all employees"),
        onSuccess: () => {
          toast.success("Transform updated for all employees");
          onSuccess && onSuccess();
        }
      }
    );
  };

  const test = async ({
    code,
    employeeId
  }: {
    code: string;
    employeeId: string;
    onSuccess?: (value?: string | null) => void;
  }) => {
    return testMutation.mutateAsync({ code, employeeId });
  };

  const remove = async ({
    fieldVariable,
    onSuccess
  }: {
    fieldVariable: string;
    onSuccess?: () => void;
  }) => {
    await deleteMutation.mutateAsync(
      { field: fieldVariable },
      {
        onError: () => toast.error("Failed to delete transform"),
        onSuccess: () => {
          toast.success("Transform deleted");
          computedField.refetch();
          onSuccess && onSuccess();
        }
      }
    );
  };

  return {
    computedField,
    test,
    updateTransform,
    updateForAllEmployees,
    remove,
    removeLoading: deleteMutation.isLoading,
    updateForAllEmployeesLoading: updateForAllEmployeesMutation.isLoading,
    updateLoading: updateMutation.isLoading,
    testLoading: testMutation.isLoading
  };
};
