import {
  Button,
  ButtonProps,
  Icon,
  OptionList,
  Popover,
  useTheme
} from "@introist/react-foundation/v2";
import { EmployeeField, useEmployeeFields } from "../hooks/useEmployeeFields";
import { useMemo, useState } from "react";
import styled from "styled-components";

const IconBackground = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  border-radius: var(--rounding-small);
  background: var(--palette-purple-ghosted);

  svg {
    width: 12px;
    height: 12px;
    fill: var(--palette-purple-default) !important;
  }
`;

export const EmployeeFieldSelector = ({
  onSelect,
  onAdd,
  fieldTypes,
  ...rest
}: {
  onSelect: (path: string) => void;
  onAdd?: (search: string) => void;
  fieldTypes?: string[];
} & Omit<ButtonProps, "onClick">) => {
  const [ref, setRef] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button ref={setRef} onClick={() => setOpen(true)} {...rest} />
      <EmployeeFieldSelectorPopover
        popoverRef={ref}
        open={open}
        setOpen={setOpen}
        onSelect={onSelect}
        onAdd={onAdd}
        fieldTypes={fieldTypes}
      />
    </>
  );
};

export const EmployeeFieldSelectorPopover = ({
  open,
  setOpen,
  onSelect,
  onAdd,
  popoverRef,
  fieldTypes,
  preSearch
}: {
  open?: boolean;
  setOpen: (open: boolean) => void;
  popoverRef: HTMLElement | null;
  onSelect: (fieldPath: string, title: string) => void;
  onAdd?: (search: string) => void;
  fieldTypes?: string[];
  preSearch?: string;
}) => {
  const { theme } = useTheme();
  const { fields } = useEmployeeFields();

  const [parentField, setParentField] = useState<EmployeeField | null>(null);

  const options = useMemo(() => {
    if (!fields) return [];
    const available = fieldTypes
      ? fields.filter(f => [...fieldTypes, "employee"].includes(f.type))
      : fields;

    const usable = parentField ? available.filter(f => f.type !== "employee") : available;
    return usable
      .filter(
        f => parentField || !preSearch || f.title.toLowerCase().includes(preSearch.toLowerCase())
      )
      .map(f => ({
        key: f.key,
        title: f.title,
        startAdornment:
          f.type === "employee" ? (
            <IconBackground>
              <Icon name="person" />
            </IconBackground>
          ) : (
            <IconBackground>
              <Icon name="attribute" />
            </IconBackground>
          ),
        onClick: () => {
          if (f.type === "employee") {
            setParentField(f);
          } else {
            const path = parentField ? `${parentField.key}.${f.key}` : f.key;
            const title = parentField ? `${parentField.title} - ${f.title}` : f.title;

            onSelect(path, title);
            setParentField(null);
            setOpen(false);
          }
        }
      }));
  }, [fields, parentField, onSelect, setOpen, fieldTypes, preSearch]);

  return (
    <Popover
      referenceElement={popoverRef}
      open={open}
      onClose={() => setOpen(false)}
      attachToRef={false}
    >
      <OptionList
        actions={
          parentField
            ? [
                {
                  key: "back",
                  title: parentField.title,
                  startAdornment: <Icon name="arrowLeft" color={theme.palette.primary.default} />,
                  onClick: () => setParentField(null)
                }
              ]
            : undefined
        }
        searchable
        onEnter={search => {
          if (onAdd) {
            onAdd(search);
            setOpen(false);
          }
        }}
        options={options}
        clearSearchOnOptionsChange
      />
    </Popover>
  );
};
