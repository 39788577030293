import { useState } from "react";
import styled from "styled-components";
import { ModalProps, Modal, Select, Layout, Button } from "@introist/react-foundation/v2";
import { Expression } from "@introist/introist-commons/dist/object-filter";

import { useEmployeeAttributeApi } from "modules/employees/api/EmployeeAttributeApi";
import { z } from "zod";
import { ExpressionEditor } from "components/organisms";

type ConditionEditorModalProps = ModalProps & {
  condition: Expression;
  conditionMode: ConditionMode;
  onChanged: (condition: Expression, mode: ConditionMode) => void;
  onRemoved: () => void;
  readonly?: boolean;
};

const StyledConditionEditorModal = styled(Modal)`
  width: 100%;
  > :first-child > :last-child {
    max-height: initial;
    overflow: visible;
  }
`;

export const ConditionModeSchema = z.enum(["cancelif", "executeif"]);
export type ConditionMode = z.infer<typeof ConditionModeSchema>;

export const ConditionEditorModalV2 = ({
  condition,
  onChanged,
  conditionMode,
  onRemoved,
  readonly,
  ...rest
}: ConditionEditorModalProps) => {
  const attributeApi = useEmployeeAttributeApi();
  const { data: attributes } = attributeApi.list({ includeHidden: false }).query;

  const [innerCondition, setInnerCondition] = useState<Expression>(condition);
  const [innerConditionMode, setInnerConditionMode] = useState<ConditionMode>(conditionMode);

  const modeSelector = (
    <Select
      size="small"
      style={{ width: "11rem" }}
      sameWidthWithReference
      options={[{ key: "executeif", title: "Execute only if" }]}
      value={innerConditionMode}
      readonly={readonly}
      onSelect={option => setInnerConditionMode(option.key as ConditionMode)}
    />
  );

  return (
    <StyledConditionEditorModal
      title="Add rules"
      {...rest}
      style={{
        maxWidth: 780
      }}
    >
      <ExpressionEditor
        attributes={attributes ?? []}
        expression={innerCondition}
        onChanged={condition => setInnerCondition(condition)}
        onRemoved={async () => onRemoved()}
        readonly={readonly}
        removeIfEmpty
        prefix={modeSelector}
      />
      <Layout.Group alignSelf="flex-end">
        <Button onClick={() => onChanged(innerCondition, innerConditionMode)}>Submit rules</Button>
      </Layout.Group>
    </StyledConditionEditorModal>
  );
};
