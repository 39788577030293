import { useState } from "react";
import styled from "styled-components";
import { H4, useToast, Button, Layout, useActions } from "@introist/react-foundation/v2";

import { UserRole, isAuthorizedAs, useIdentity } from "IdentityProvider";

import { api } from "services/rpc/RpcProvider";

import { UserList, TimezoneSelect, WorkspaceDateFormatSelect, InviteUserModal } from "./components";
import { ListSection } from "components/organisms";
import { clearSelectedWorkspace } from "../../../../services/workspace";
import { logout } from "../../../../services/firebase";
import { EditableHeading, ImageUploadArea } from "components/molecules";

const StyledWorkspaceSettingsRoute = styled(Layout.Group)`
  flex: 1;
  user-select: none;
`;

export const WorkspaceSettingsRoute = () => {
  const { onConfirmAction } = useActions();
  const toast = useToast();
  const { user } = useIdentity();
  const isOwner = isAuthorizedAs(user, UserRole.Owner);

  const { data: organisation, refetch: refetchWorkspace } = api.workspaces.find.useQuery({});
  const { data: users, isLoading, refetch: refetchUsers } = api.users.list.useQuery({});

  const [inviteOpen, setInviteOpen] = useState(false);

  const updateOrg = api.workspaces.update.useMutation({
    onSuccess: async () => {
      await refetchWorkspace();
      toast.success("Saved");
    },
    onError: () => {
      toast.error("Failed to save changes");
    }
  });

  const removeUser = api.users.delete.useMutation({
    onSuccess: async () => {
      toast.success("User removed");
      await refetchUsers();
    },
    onError: () => {
      toast.error("Failed to remove user");
    }
  });

  const inviteUser = api.users.invite.useMutation({
    onSuccess: async () => {
      toast.success("User added");
      await refetchUsers();
      setInviteOpen(false);
    },
    onError: () => {
      toast.error("Failed to add user");
    }
  });

  const { mutate: changeRole } = api.users.update.useMutation({
    onSuccess: async () => {
      toast.success("Role changed");
      await refetchUsers();
    },
    onError: () => {
      toast.error("Failed to update role");
    }
  });

  const { mutateAsync: deleteWorkspace } = api.workspaces.delete.useMutation({});

  const onDelete = onConfirmAction(
    async () => {
      await deleteWorkspace({})
        .then(() => {
          clearSelectedWorkspace();
          logout();
          window.location.href = "/";
        })
        .catch(() => toast.error("Failed to delete workspace"));
    },
    {
      title: "Delete workspace",
      description: "Are you sure you want to delete this workspace?",
      confirmTitle: "Delete"
    }
  );

  return (
    <StyledWorkspaceSettingsRoute vertical gap="xLarge">
      <Layout.Group vertical gap="xxLarge">
        <Layout.Group gap="xLarge">
          <ImageUploadArea
            onImageUploaded={logo => updateOrg.mutateAsync({ logo }).catch(() => {})}
            imageUrl={organisation?.logo}
          />
          <Layout.Group>
            <EditableHeading
              editable
              onSave={name => updateOrg.mutateAsync({ name }).catch(() => {})}
              initialValue={organisation?.name ?? ""}
            >
              <H4>{organisation?.name}</H4>
            </EditableHeading>
          </Layout.Group>
        </Layout.Group>
        <Layout.Group vertical>
          <TimezoneSelect
            selectedTimezone={organisation?.defaultTimezone}
            onSelect={(timezone: string) => updateOrg.mutateAsync({ defaultTimezone: timezone })}
          />
          {organisation?.dateFormat && (
            <WorkspaceDateFormatSelect
              dateFormat={organisation.dateFormat}
              onSelect={async (dateFormat: string) => updateOrg.mutateAsync({ dateFormat })}
            />
          )}
        </Layout.Group>
      </Layout.Group>
      <Layout.Divider />
      <ListSection
        title="Workspace members"
        actions={
          isOwner && (
            <Button
              startIcon="plus"
              variant="outlined"
              style={{ marginLeft: "auto" }}
              onClick={() => setInviteOpen(true)}
            >
              Invite
            </Button>
          )
        }
      >
        <UserList
          readOnly={!isOwner}
          users={users || []}
          loading={isLoading}
          onRemoveUser={userId => removeUser.mutateAsync({ userId }).catch(() => {})}
          onChangeRole={(userId, role) =>
            changeRole({
              userId,
              role
            })
          }
        />
      </ListSection>

      {isOwner && (
        <>
          <Layout.Divider />
          <div>
            <Button variant="outlined" destructive onClick={onDelete}>
              Delete workspace
            </Button>
          </div>
        </>
      )}

      <InviteUserModal
        open={inviteOpen}
        organisationLogo={organisation?.logo}
        onClose={() => setInviteOpen(false)}
        onAddUser={email => inviteUser.mutateAsync({ email })}
      />
    </StyledWorkspaceSettingsRoute>
  );
};
