import { AttachmentsBlock } from "./Attachments";
import { EmailStepAddressesBlock } from "./EmailStepAddresses/v2";
import { HeaderBlockV2 } from "./HeaderBlock/v2";
import { StepTypeBlock } from "../components/steps/StepTypeBlock";
import { ActiveBlock } from "./Active";
import { StepTitleBlock } from "../components/steps/StepTitleBlock/StepTitleBlock";

export const WorkflowEditorBlock = {
  Header: HeaderBlockV2,
  StepType: StepTypeBlock,
  StepTitle: StepTitleBlock,
  EmailAddresses: EmailStepAddressesBlock,
  Attachments: AttachmentsBlock,
  Active: ActiveBlock
};
