import { H4, Icon, IconName, Layout, Title } from "@introist/react-foundation/v2";
import styled from "styled-components";

const BigIcon = styled(Icon)`
  width: 24px;
  height: 24px;
`;

interface Props {
  iconName: IconName;
  title: string;
  description: string;
}

const Container = styled(Layout.Group)`
  padding-left: var(--spacing-large);
`;
const Details = styled(Layout.Group)`
  max-width: 330px;
`;

export const AccountsHeader = ({ iconName, title, description }: Props) => {
  return (
    <Container gap="xLarge">
      <BigIcon name={iconName} />
      <Details vertical>
        <H4>{title}</H4>
        <Title>{description}</Title>
      </Details>
    </Container>
  );
};
