import { isEmail } from "../../../utils/string-templater";
import { EventStepSchemaFields } from "./event-step";
import { SlackStepSchemaFields } from "./slack-step";
import { WebhookStepSchemaFields } from "./webhook-step";
import { EnrollStepSchemaFields } from "./enroll-step";
import { CreateGoogleAccountStepSchemaFields } from "./create-google-account-step";
import { InviteStepSchemaFields } from "./invite-step";
import { EmailFormStepSchemaFields } from "./email-form-step";
import { StepTypeV2, StepTypesV2 } from "services/rpc/RpcProvider";

export const isEmailOrProperty = (value: string, properties?: string[]) => {
  if (!value) return true;
  if (isEmail(value)) return true;
  if (!properties) return true;
  return properties.includes(value);
};

export const emailOrEmployeeAttributeSchemaField = (required: boolean, properties?: string[]) => {
  return {
    type: "string",
    use: {
      validateInput: (value: string) => isEmailOrProperty(value, properties)
    },
    message: {
      validateInput: "invalid email or property"
    },
    required
  };
};

export const getSchemaFieldsForStepContent = (stepType: StepTypeV2): any => {
  if (stepType === StepTypesV2.Enum.event) return EventStepSchemaFields();
  if (stepType === StepTypesV2.Enum.slack) return SlackStepSchemaFields;
  if (stepType === StepTypesV2.Enum.webhook) return WebhookStepSchemaFields;
  if (stepType === StepTypesV2.Enum.enroll) return EnrollStepSchemaFields;
  if (stepType === StepTypesV2.Enum.creategoogleaccount) return CreateGoogleAccountStepSchemaFields;
  if (stepType === StepTypesV2.Enum.invite) return InviteStepSchemaFields;
  if (stepType === StepTypesV2.Enum.emailform) return EmailFormStepSchemaFields;
};
