import styled from "styled-components";
import {
  DataForm,
  Title,
  Toggle,
  Layout,
  Select,
  SelectProps
} from "@introist/react-foundation/v2";

import { Block } from "components/atoms";
import { StepEditorAccordion } from "modules/workflows/routes/WorkflowEditor/StepEditorAccordion";
import { WorkflowStep } from "services/api/WorkflowApi";
import { JourneyStepV2 } from "services/rpc/RpcProvider";
import { useMemo } from "react";

type EventSettingsBlockProps = {
  form: DataForm<WorkflowStep> | DataForm<JourneyStepV2>;
  readOnly?: boolean;
  formPrefix?: string;
};

const Setting = styled(Layout.Group).attrs({ justifyContent: "space-between" })`
  // Toggle
  > :last-child {
    flex-shrink: 0;
  }
`;

export const useEventSettings = (
  form: DataForm<WorkflowStep> | DataForm<JourneyStepV2>,
  formPrefix: string
) => {
  const settings = [];
  const isCalendarEvent = form.data.stepType === "event";
  const isCommonEventType = form.get(`${formPrefix}eventType`) === "common";

  if (isCalendarEvent) {
    if (!isCommonEventType) {
      settings.push({
        title: "Sync attendees",
        description: "Update calendar event attendees whenever employees fields change.",
        key: `${formPrefix}syncAttendees`
      });
    }

    settings.push({
      title: "Private event",
      description:
        "Create this event as private so only guests can see summary and description of the event.",
      key: `${formPrefix}private`
    });

    if (isCommonEventType) {
      settings.push({
        title: "Keep empty event",
        description: "Do not remove event from calendar even if there are no more attendees",
        key: `${formPrefix}keepEmptyEvent`
      });
    }

    if (isCommonEventType) {
      settings.push({
        title: "Do not create new events",
        description: "Do not create new event if no suitable event is found",
        key: `${formPrefix}doNotCreate`
      });
    }
  }

  const hasActiveSettings = settings.reduce((acc, curr) => {
    return acc || !!form.get(curr.key);
  }, false);

  return {
    settings,
    hasActiveSettings,
    hasSettings: settings.length > 0,
    isCommonEventType
  };
};

export const EventSettingsBlock = ({
  form,
  readOnly,
  formPrefix = "stepData.",
  ...rest
}: EventSettingsBlockProps) => {
  const { settings, isCommonEventType } = useEventSettings(form, formPrefix);

  const guestSettingOptions = { null: "Use Calendar default", true: "Allow", false: "Deny" };

  return (
    <Block compact blended {...rest}>
      <StepEditorAccordion title="Event settings" defaultOpen={false}>
        {settings.map((setting, index) => {
          return (
            <Setting key={`step-editor-event-setting-${index}`}>
              <Layout.Group vertical gap="xSmall">
                <Title variant="bold">{setting.title}</Title>
                <Title>{setting.description}</Title>
              </Layout.Group>
              <Toggle
                disabled={!!readOnly}
                checked={form.get(setting.key)}
                onChange={form.set(setting.key)}
              />
            </Setting>
          );
        })}
        {!isCommonEventType && (
          <>
            <Setting key={`step-editor-event-setting-guests-can-see`}>
              <Layout.Group vertical gap="xSmall">
                <Title variant="bold">Guests can see guest list</Title>
                <Title></Title>
              </Layout.Group>
              <TriStateBooleanSelect
                size="small"
                labels={guestSettingOptions}
                value={form.get(`${formPrefix}guestsCanSeeGuestList`)}
                onSelect={form.set(`${formPrefix}guestsCanSeeGuestList`)}
              />
            </Setting>
            <Setting key={`step-editor-event-setting-guests-can-invite`}>
              <Layout.Group vertical gap="xSmall">
                <Title variant="bold">Guests can invite others</Title>
                <Title></Title>
              </Layout.Group>
              <TriStateBooleanSelect
                size="small"
                labels={guestSettingOptions}
                value={form.get(`${formPrefix}guestsCanInviteOthers`)}
                onSelect={form.set(`${formPrefix}guestsCanInviteOthers`)}
              />
            </Setting>
            <Setting key={`step-editor-event-setting-guests-can-modify`}>
              <Layout.Group vertical gap="xSmall">
                <Title variant="bold">Guests can modify event</Title>
                <Title></Title>
              </Layout.Group>
              <TriStateBooleanSelect
                size="small"
                labels={guestSettingOptions}
                value={form.get(`${formPrefix}guestsCanModifyEvent`)}
                onSelect={form.set(`${formPrefix}guestsCanModifyEvent`)}
              />
            </Setting>
          </>
        )}
      </StepEditorAccordion>
    </Block>
  );
};

type TriStateBooleanOptions = {
  null: string;
  true: string;
  false: string;
};

type TriStateBooleanSelectProps = Omit<SelectProps, "options" | "onSelect"> & {
  labels: TriStateBooleanOptions;
  onSelect: (value: boolean | null) => void;
};

const TriStateBooleanSelect = ({
  labels,
  value,
  onSelect,
  ...props
}: TriStateBooleanSelectProps) => {
  const options = useMemo(() => {
    return Object.entries(labels).map(([key, title]) => ({ key, title }));
  }, [labels]);

  const innerValue = useMemo(() => {
    if (value === null || value === undefined || value === "") return "null";
    if (value === "true") return "true";
    if (value === "false") return "false";
    return value ? "true" : "false";
  }, [value]);

  return (
    <Select
      {...props}
      attachToRef={false}
      options={options}
      value={innerValue}
      onSelect={opt => {
        if (opt.key === "null") return onSelect(null);
        if (opt.key === "true") return onSelect(true);
        if (opt.key === "false") return onSelect(false);
      }}
    />
  );
};
