import { useMemo } from "react";
import { DataForm, Icon, InfoCard, Layout, Select, Title } from "@introist/react-foundation/v2";

import { Block } from "components/atoms";
import { prefixForm } from "utils/FormUtils";
import { useIntegrationsApi } from "services/api/IntegrationsApi";
import { WorkflowStep } from "services/api/WorkflowApi";
import { StepEditorAccordion } from "../../StepEditorAccordion";
import { InstructionEmailEditor } from "./blocks/InstructionEmailEditor";

type CreateGoogleAccountEditorV2Props = {
  form: DataForm<WorkflowStep>;
  formPrefix?: string;
  readonly?: boolean;
};

export const CreateGoogleAccountEditorV2 = ({
  form,
  formPrefix,
  readonly
}: CreateGoogleAccountEditorV2Props) => {
  const prefixedForm = prefixForm(form, formPrefix);

  const integrationApi = useIntegrationsApi();
  const { data: integrationOptions } = integrationApi.loadOptions({
    integrationId: "googleworkspace"
  }).query;

  const emailOptions = useMemo(() => {
    return [
      { key: "{{firstname}}.{{lastname}}", title: "firstname.lastname" },
      { key: "{{firstname}}", title: "firstname" }
    ];
  }, []);

  const domainOptions = useMemo(() => {
    if (!integrationOptions) return [];
    return integrationOptions.domains.map((domain: any) => ({ key: domain.domainName }));
  }, [integrationOptions]);

  return (
    <>
      <Block compact blended>
        <StepEditorAccordion title="Google account details">
          <Layout.Group vertical gap="xLarge">
            <InfoCard
              hideIcon
              title="An email address will be created for the employee using this information."
              colorVariant="primary"
            />
            <>
              {
                <Layout.Group>
                  <Select
                    size="small"
                    placeholder="User's email"
                    options={emailOptions}
                    value={prefixedForm.get("emailTemplate")}
                    onSelect={opt => prefixedForm.set("emailTemplate")(opt.key)}
                    error={form.isError("emailTemplate")}
                    readonly={readonly}
                  />
                  <Icon name="atTheRate" />
                  <Select
                    size="small"
                    placeholder="Domain"
                    options={domainOptions}
                    value={prefixedForm.get("domain")}
                    onSelect={opt => prefixedForm.set("domain")(opt.key)}
                    error={form.isError("domain")}
                    readonly={readonly}
                  />
                </Layout.Group>
              }
            </>
          </Layout.Group>
        </StepEditorAccordion>

        {readonly && <Title>This is the email address that was created for the Employee.</Title>}
      </Block>
      <InstructionEmailEditor prefixedForm={prefixedForm} readOnly={readonly} />
    </>
  );
};
