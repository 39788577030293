import { IconToggleButton } from "../../../workflows/components/IconToggleButton";
import {
  Icon,
  LabelledCheckbox,
  Select,
  Stack,
  Title,
  useTheme
} from "@introist/react-foundation/v2";
import { CardPopover } from "../../../workflows/routes/WorkflowEditor/CardPopover";
import { useMemo, useRef, useState } from "react";
import { RelativeDay } from "../../../../services/api/WorkflowApi";
import { TriggerDayPicker } from "../../../../components/molecules";

type TimeRangeEditorValue = { start: RelativeDay; end: RelativeDay; failIfNoTime?: boolean };

export type FindTimeRangeEditorProps = {
  value?: TimeRangeEditorValue | null;
  onChange: (value: TimeRangeEditorValue | null) => void;
};

export const MeetingFlexEditor = ({ value, onChange }: FindTimeRangeEditorProps) => {
  const { theme } = useTheme();

  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);

  const buttonText = useMemo(() => {
    if (!value) return "Set availability search";

    const start = `- ${value.start.amount} ${value.start.period}`;
    const end = `+ ${value.end.amount} ${value.end.period}`;

    return `${start} / ${end}`;
  }, [value]);

  return (
    <div>
      <IconToggleButton
        active={open}
        ref={buttonRef}
        onClick={() => setOpen(!open)}
        startAdornment={
          <Icon name="searchCircle" color={!value ? theme.palette.foreground.subdued : undefined} />
        }
      >
        <Stack gap="xSmall">
          {!value && <Icon name="plusSmall" color={theme.palette.foreground.subdued} />}
          <span style={{ color: !value ? theme.palette.foreground.subdued : undefined }}>
            {buttonText}
          </span>
        </Stack>
      </IconToggleButton>
      <CardPopover open={open} referenceElement={buttonRef.current} onClose={() => setOpen(false)}>
        <FlexRangeEditorPopover value={value} onChange={onChange} />
      </CardPopover>
    </div>
  );
};

const FlexRangeEditorPopover = ({
  value,
  onChange
}: {
  value?: TimeRangeEditorValue | null;
  onChange: (value: TimeRangeEditorValue | null) => void;
}) => {
  const { theme } = useTheme();

  return (
    <Stack vertical gap="xLarge">
      <LabelledCheckbox
        label="Use availability search"
        checked={!!value}
        onChange={checked => {
          if (checked) {
            onChange({
              start: { amount: 0, period: "days", side: "before" },
              end: { amount: 0, period: "days", side: "after" }
            });
          } else {
            onChange(null);
          }
        }}
      />

      <TriggerDayPicker
        startIcon="minusSmall"
        onChange={start => onChange({ ...value!, start })}
        trigger={value?.start ?? { amount: 0, period: "days", side: "before" }}
        disableSide
        disabled={!value}
        minAmount={0}
        supportHours
      />
      <Stack gap="large">
        <Icon name="calendar" color={theme.palette.foreground.subdued} />
        <Title>Meetings scheduled time</Title>
      </Stack>
      <TriggerDayPicker
        startIcon="plusSmall"
        onChange={end => onChange({ ...value!, end })}
        trigger={value?.end ?? { amount: 0, period: "days", side: "after" }}
        disableSide
        disabled={!value}
        minAmount={0}
        supportHours
      />
      <Stack vertical gap="small">
        <Title>If no available time is found</Title>
        <Select
          style={{ width: "100%" }}
          size="small"
          value={value?.failIfNoTime ? "true" : "false"}
          disabled={!value}
          options={[
            { key: "false", title: "Schedule to default time" },
            { key: "true", title: "Mark failing" }
          ]}
          onSelect={opt => onChange({ ...value!, failIfNoTime: opt.key === "true" })}
        />
      </Stack>
    </Stack>
  );
};
