import {
  Button,
  CircularLoader,
  Field,
  Icon,
  Layout,
  Modal,
  Option,
  Select,
  useToast
} from "@introist/react-foundation/v2";
import { useLocation } from "react-router-dom";
import { api, DataSourceSyncMode, EmpDataSource } from "services/rpc/RpcProvider";

import { TextInput } from "components/atoms";
import { useCallback, useMemo, useState } from "react";
import { GenericError } from "components/molecules";

type Props = {
  dataSourceId: string;
  onClose: VoidFunction;
};

const useDataSourceFieldMapSelectOptions = (dataSourceId: string) => {
  const toast = useToast();
  const { data: employeeFields } = api.employees.fields.list.useQuery({});
  const { data: dataSourceFields } = api.employees.dataSources.fields.list.useQuery(
    { sourceId: dataSourceId || "" },
    {
      onError: () => {
        toast.error("Failed to fetch data source fields");
      }
    }
  );

  const dataSourceFieldOptions: Option[] = useMemo(() => {
    if (!dataSourceFields) return [];

    return dataSourceFields.map((field, idx) => ({
      key: field.jsonPath || `unknown-data-source-field-${idx}`,
      title: field.name || field.jsonPath || "Unknown"
    }));
  }, [dataSourceFields]);

  const employeeFieldOptions: Option[] = useMemo(() => {
    if (!employeeFields) return [];

    return employeeFields.map(field => ({
      key: field.variable,
      title: field.name
    }));
  }, [employeeFields]);

  return {
    dataSourceFieldOptions,
    employeeFieldOptions
  };
};

export const DataSourceConnectionEditor = ({ dataSourceId, onClose }: Props) => {
  const toast = useToast();

  const update = api.employees.dataSources.update.useMutation();

  const { data: dataSource, refetch } = api.employees.dataSources.find.useQuery({ dataSourceId });
  const { refetch: refetchDataSources } = api.employees.dataSources.list.useQuery({});

  const onSave = useCallback(
    async (value: EmpDataSource) => {
      await update
        .mutateAsync({
          dataSourceId,
          updates: {
            name: value.name,
            syncMode: value.syncMode,
            sourceIdentifierField: value.sourceIdentifierField,
            targetIdentifierField: value.targetIdentifierField,
            cron: value.cron
          }
        })
        .then(() => {
          refetchDataSources();
          refetch();
          toast.success("Data source connection updated");
          onClose();
        })
        .catch(() => {
          toast.error("Failed to update data source connection");
        });
    },
    [dataSourceId, toast, refetchDataSources, update, onClose, refetch]
  );

  if (!dataSource) return <CircularLoader fillParent colorVariant="primary" />;

  return <DataSourceForm defaultValue={dataSource} onCancel={onClose} onSubmit={onSave} />;
};

const KNOWN_CRON_OPTIONS = [
  {
    title: "Daily at 6:00 (UTC)",
    key: "0 6 * * 1-5"
  },
  {
    title: "Twice daily at 6:00 and 12:00 (UTC)",
    key: "0 6,12 * * 1-5"
  },
  {
    title: "Three times daily at 6:00, 11:00, and 16:00 (UTC)",
    key: "0 6,11,16 * * 1-5"
  },
  {
    title: "Every two hours from 6:00 to 18:00 (UTC)",
    key: "0 6-18/2 * * 1-5"
  }
];

const DataSourceForm = ({
  defaultValue,
  onSubmit,
  onCancel
}: {
  defaultValue: EmpDataSource;
  onSubmit: (value: EmpDataSource) => Promise<void>;
  onCancel: VoidFunction;
}) => {
  const { employeeFieldOptions, dataSourceFieldOptions } = useDataSourceFieldMapSelectOptions(
    defaultValue.id
  );

  const [value, setValue] = useState(defaultValue);

  const syncModeOptions = useMemo(
    () => [
      {
        key: "existing",
        title: "Employees in Introist"
      },
      {
        key: "full",
        title: "All employees in data source"
      }
    ],
    []
  );

  if (!value || !employeeFieldOptions || !dataSourceFieldOptions) {
    return <CircularLoader fillParent colorVariant="primary" />;
  }

  return (
    <Layout.Group vertical gap="xxLarge">
      <Layout.Group vertical gap="xLarge">
        <TextInput
          label="Name"
          value={value.name}
          onChange={name => setValue({ ...value, name })}
        />
        <TextInput label="Data category" value={value.dataCategory} disabled />

        <Field title="Sync mode">
          <Select
            style={{ width: "100%" }}
            value={value.syncMode}
            options={syncModeOptions}
            onSelect={opt => setValue({ ...value, syncMode: opt.key as DataSourceSyncMode })}
          />
        </Field>
        <Field title="Auto-Sync frequency">
          <Select
            style={{ width: "100%" }}
            value={value.cron}
            options={KNOWN_CRON_OPTIONS}
            onSelect={opt => setValue({ ...value, cron: opt.key as string })}
          />
        </Field>
        <Layout.Group>
          <Field title="Source field">
            <Select
              value={value.sourceIdentifierField ?? undefined}
              options={dataSourceFieldOptions}
              onSelect={opt => setValue({ ...value, sourceIdentifierField: opt.key as string })}
            />
          </Field>
          <Icon name="arrowRight" style={{ marginBottom: -22 }} />
          <Field title="Target field">
            <Select
              value={value.targetIdentifierField ?? undefined}
              options={employeeFieldOptions}
              onSelect={opt => setValue({ ...value, targetIdentifierField: opt.key as string })}
            />
          </Field>
        </Layout.Group>
      </Layout.Group>

      <Layout.Group justifyContent="flex-end" gap="large">
        <Button variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClickWithLoading={() => onSubmit(value)}>Save</Button>
      </Layout.Group>
    </Layout.Group>
  );
};

export const DataSourceConnectionEditorModal = ({
  open,
  onClose,
  dataSourceId: dataSourceIdProp,
  ...rest
}: {
  dataSourceId?: string;
  open: boolean;
  onClose: VoidFunction;
}) => {
  const location = useLocation();
  const dataSourceIdFromHash = location.hash.split("=")[1];
  const dataSourceId = dataSourceIdProp || dataSourceIdFromHash;

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Edit data source connection"
      maxContentHeight="70vh"
    >
      {open && !dataSourceId && <GenericError asCard />}
      {dataSourceId && (
        <DataSourceConnectionEditor {...rest} dataSourceId={dataSourceId} onClose={onClose} />
      )}
    </Modal>
  );
};
