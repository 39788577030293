import {
  ActionMenu,
  CircularLoader,
  Icon,
  Stack,
  Tag,
  Title,
  Tooltip,
  useToast
} from "@introist/react-foundation/v2";
import { Avatar } from "components/molecules";
import { CollapseList, ListRow, NoResults } from "components/organisms";
import { useCallback, useMemo, useState } from "react";
import { api, RouterOutput } from "services/rpc/RpcProvider";
import { ActiveStatusTag, JourneyProgress, StatusTag } from "../../journeys";
import { formatDate } from "../../../utils/DatesUtils";
import { DateFormats } from "../../../utils/dates";
import { compact, partition } from "lodash";
import { AUTOMATION_ROUTES } from "../../automations/routes";
import sortBy from "lodash.sortby";
import { Automation, useAutomations } from "../../automations/hooks/useAutomations";

type Props = {
  triggerId: string;
};

type TriggerActivation = RouterOutput["triggers"]["activations"]["list"][0];

const failingFirst = (a: TriggerActivation) => (a.activatedAt ? 1 : 0);

export const TriggerLog = ({ triggerId }: Props) => {
  const toast = useToast();
  const activations = api.triggers.activations.list.useQuery({ triggerId });

  const skipOp = api.triggers.activations.skip.useMutation();
  const mergeOp = api.triggers.activations.merge.useMutation();

  const { automations } = useAutomations({});

  const [activeOpen, setActiveOpen] = useState(true);
  const [historyOpen, setHistoryOpen] = useState(false);

  const [active, history] = useMemo(() => {
    if (!activations.data) return [[], []];
    return partition(activations.data, a => !a.completedAt);
  }, [activations.data]);

  const skipFailing = useCallback(
    async (activation: TriggerActivation) => {
      await skipOp
        .mutateAsync({
          triggerId: activation.triggerId,
          employeeId: activation.employeeId,
          activationId: activation.id
        })
        .then(async () => {
          await activations.refetch();
        })
        .catch(() => {
          toast.error("Failed to skip automation");
        });
    },
    [activations, skipOp, toast]
  );

  const merge = useCallback(
    async (activationId: string) => {
      await mergeOp
        .mutateAsync({
          activationId
        })
        .then(async () => {
          await activations.refetch();
        })
        .catch(() => {
          toast.error("Failed to connect automation");
        });
    },
    [activations, mergeOp, toast]
  );

  if (!activations.data) {
    return <CircularLoader fillParent />;
  }

  if (activations.data && activations.data.length === 0) {
    return (
      <NoResults
        icon="trigger"
        title="No automations yet"
        message={
          <Stack vertical gap="xSmall" alignItems="center">
            <Title>This trigger has not been started for any employee yet.</Title>
            <Title>Once it is, you will see the history here.</Title>
          </Stack>
        }
      />
    );
  }

  const getTo = (journeyId: string) => {
    return AUTOMATION_ROUTES.automation.path.replace(":automationId", journeyId);
  };

  const renderRow = (a: TriggerActivation, j: Automation | null | undefined) => {
    const isActiveJourney = j?.status && ["scheduled", "delivered", "failing"].includes(j.status);
    return (
      <ListRow
        key={`trigger-activation-entry-${a.id}`}
        gridTemplateColumns="auto auto 1fr auto"
        onClick={() => {
          const to = j && j.id ? getTo(j.id) : undefined;
          if (to) window.open(to, "_blank");
        }}
      >
        <Avatar nameOrEmail={a.employee?.name} />
        <Title variant="bold">{a.employee ? a.employee.name : "Deleted employee"}</Title>
        {a.activatedAt ? (
          <Title small>Started {formatDate(a.activatedAt, DateFormats.date.long)}</Title>
        ) : (
          <div />
        )}
        {j && (
          <Stack>
            {j.status === "failing" && <Icon danger name="warning" />}
            {j.status && (
              <>{isActiveJourney ? <ActiveStatusTag /> : <StatusTag status={j.status} />}</>
            )}
            {j.completedStepCount < j.totalStepCount && isActiveJourney && (
              <JourneyProgress
                journeyStatus={j.status ? j.status : undefined}
                completedStepCount={j.completedStepCount}
                totalStepCount={j.totalStepCount}
              />
            )}
          </Stack>
        )}
        {!a.activatedAt && (
          <Stack>
            <Tooltip tooltip={a.error ?? ""}>
              <Tag colorVariant="danger">Failing</Tag>
            </Tooltip>
            <ActionMenu
              options={compact([
                a.error?.startsWith("Employee already has this workflow active")
                  ? {
                      key: "merge",
                      title: "Connect to active automation",
                      startAdornmentIcon: "featureRoutines",
                      onClick: () => merge(a.id)
                    }
                  : null,
                {
                  key: "skip",
                  title: "Skip",
                  startAdornmentIcon: "crossCircle",
                  onClick: () => skipFailing(a)
                }
              ])}
            />
          </Stack>
        )}
      </ListRow>
    );
  };

  return (
    <Stack vertical>
      <CollapseList
        open={activeOpen}
        emptyState={
          activations.data &&
          active.length === 0 && (
            <NoResults
              icon="trigger"
              title="No active automations"
              message={
                <Stack vertical gap="xSmall" alignItems="center">
                  <Title>This trigger is not currently active for any employee.</Title>
                  <Title>You can find completed automations in the history below.</Title>
                </Stack>
              }
            />
          )
        }
        onToggleOpen={() => setActiveOpen(!activeOpen)}
        header={
          <Stack gap="small">
            <Title variant="bold">Active</Title>
            {active && <Title small>{active.length.toString()}</Title>}
          </Stack>
        }
      >
        {sortBy(active, failingFirst).map(a => {
          const automation = (automations ?? []).find(automation => automation.id === a.journeyId);
          return renderRow(a, automation);
        })}
      </CollapseList>

      <CollapseList
        open={historyOpen}
        emptyState={
          activations.data &&
          history.length === 0 && (
            <ListRow gridTemplateColumns="1fr">
              <Title>No finished automations</Title>
            </ListRow>
          )
        }
        onToggleOpen={() => setHistoryOpen(!historyOpen)}
        header={
          <Stack gap="small">
            <Title variant="bold">History</Title>
            {<Title small>{history.length.toString()}</Title>}
          </Stack>
        }
      >
        {history.map(activation => {
          const automation = (automations ?? []).find(
            automation => automation.id === activation.journeyId
          );
          return renderRow(activation, automation);
        })}
      </CollapseList>
    </Stack>
  );
};
