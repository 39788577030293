import { useEffect, useState } from "react";
import { UploadedFile, uploadFile } from "services/api/FileApiV2";

export const useUploadFile = (file: File) => {
  const [progress, setProgress] = useState(0);
  const [uploadedFile, setUploadedFile] = useState<UploadedFile | undefined>(undefined);

  useEffect(() => {
    setUploadedFile(undefined);
    setProgress(0);
    uploadFile(file, {
      onUploadProgress: progressEvent => {
        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        setProgress(progress);
      },
      onDownloadProgress: progressEvent => {
        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        setProgress(progress);
      }
    }).then(setUploadedFile);
  }, [file]);

  return { progress, uploadedFile };
};
