import { useCallback, useRef, useState } from "react";
import styled from "styled-components";
import {
  Popover,
  Card,
  useTheme,
  Layout,
  Button,
  Icon,
  Stack,
  Tooltip
} from "@introist/react-foundation/v2";
import { useNavigate } from "react-router-dom";

import { JourneyOverview } from "services/rpc/RpcProvider";
import { JourneyProgress } from "modules/journeys";

import { FittedTitle } from "components/atoms";
import { AUTOMATION_ROUTES } from "../../../../../automations/routes";

type EmployeeJourneySelectorProps = {
  journeys: JourneyOverview[];
};

const StyledEmployeeJourneySelector = styled(Layout.Group)`
  user-select: none;
`;

export const EmployeeJourneySelector = ({ journeys, ...rest }: EmployeeJourneySelectorProps) => {
  const { theme } = useTheme();
  const popoverAnchorRef = useRef<HTMLButtonElement | null>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  if (journeys.length === 0) {
    return null;
  }

  const sortedJourneys = journeys.sort((a, b) => {
    // sort by failing
    return Number(b.status === "failing") - Number(a.status === "failing");
  });

  const hasFailingJourneys = sortedJourneys.filter(j => j.status === "failing").length > 0;

  return (
    <StyledEmployeeJourneySelector {...rest}>
      {journeys.length === 1 && <JourneyInfoRow journey={journeys[0]} />}
      {journeys.length > 1 && (
        <Button
          active={popoverOpen}
          size="small"
          variant="blended"
          endIcon="chevronDown"
          ref={popoverAnchorRef}
          startAdornment={
            hasFailingJourneys && (
              <Tooltip tooltip="Failing steps">
                <Icon name="warning" color={theme.palette.danger.default} />
              </Tooltip>
            )
          }
          onClick={e => {
            setPopoverOpen(!popoverOpen);
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {journeys.length} Automations
        </Button>
      )}
      <Popover
        referenceElement={popoverAnchorRef.current}
        open={popoverOpen}
        placement="bottom-end"
        onClose={() => setPopoverOpen(false)}
      >
        <Card elevated>
          <Stack vertical gap="none">
            {sortedJourneys.map(journey => (
              <JourneyInfoRow key={`journey-selector-row-${journey.id}`} journey={journey} />
            ))}
          </Stack>
        </Card>
      </Popover>
    </StyledEmployeeJourneySelector>
  );
};

const StyledJourneyInfoRow = styled(Button)`
  > div > h5 {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

const JourneyInfoRow = ({ journey }: { journey: JourneyOverview }) => {
  const { theme } = useTheme();
  const navigate = useNavigate();

  const onOpenJourney = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      navigate(AUTOMATION_ROUTES.automation.path.replace(":automationId", journey.id));
    },
    [navigate, journey.id]
  );

  return (
    <StyledJourneyInfoRow
      size="small"
      variant="blended"
      onClick={onOpenJourney}
      startAdornment={
        journey.status === "failing" && (
          <Tooltip tooltip="Failing steps">
            <Icon name="warning" color={theme.palette.danger.default} />
          </Tooltip>
        )
      }
    >
      <Stack justifyContent="space-between" gap="small">
        <FittedTitle
          as="div"
          showTooltip
          maxLength={40}
          variant="bold"
          color={theme.palette.foreground.default}
        >
          {journey.workflow?.name || "Unknown"}
        </FittedTitle>
        <JourneyProgress
          hideText
          journeyStatus={journey.status}
          totalStepCount={journey.totalStepCount}
          completedStepCount={journey.completedStepCount}
        />
      </Stack>
    </StyledJourneyInfoRow>
  );
};
