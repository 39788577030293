import { useRef } from "react";
import { Layout } from "@introist/react-foundation/v2";
import { EmployeeAttribute } from "modules/employees/api/EmployeeAttributeApi";
import { StepEditorAccordion } from "../../../StepEditorAccordion";
import { SlackSenderSelectV2 } from "../../SlackStepEditorV2/blocks/SenderAndRecipients/components/SlackSenderSelectV2";
import { SlackUserSelectV2 } from "../../SlackStepEditorV2/blocks/SenderAndRecipients/components/SlackUserSelectV2";
import { Block } from "components/atoms";
import { Editor, HtmlEditorPowerUps, HtmlContentEditor } from "components/organisms";

interface SlackMessageSpec {
  senderId: string;
  channels: string[];
  users: string[];
  message: string;
}

type Props = {
  value: SlackMessageSpec;
  onChanged: (value: SlackMessageSpec) => void;
  readOnly?: boolean;
  title?: string;
  stepOrder?: number;
};

export const SlackMessageBuilder = ({ value, onChanged, readOnly, title, stepOrder }: Props) => {
  const messageRef = useRef<Editor>(null);

  const addVariable = (variable: EmployeeAttribute) => {
    if (messageRef.current !== null && messageRef.current.hasFocus()) {
      messageRef.current.insertUserPropertyToCursorPosition(variable);
      messageRef.current.insertTextToCursorPosition(" ");
    }
  };

  return (
    <Block blended compact>
      <StepEditorAccordion title={title ?? "Message details"} stepOrder={stepOrder ?? 3}>
        <Layout.Group vertical gap="xLarge">
          <StepEditorAccordion.Row label="From">
            <SlackSenderSelectV2
              senderId={value.senderId}
              onSelectSender={senderId => onChanged({ ...value, senderId: senderId! })}
              readOnly={readOnly}
            />
          </StepEditorAccordion.Row>
          <StepEditorAccordion.MultiOptionRow label="To">
            <SlackUserSelectV2
              senderId={value.senderId}
              onSelect={users => onChanged({ ...value, users })}
              initialSelected={value.users || []}
              readOnly={readOnly}
            />
          </StepEditorAccordion.MultiOptionRow>
          <HtmlContentEditor
            ref={messageRef}
            defaultValue={value.message}
            onChange={message => onChanged({ ...value, message })}
            onGenerated={text => messageRef.current?.replaceHTML(text)}
            channel="slack"
            readonly={readOnly}
          />
          {!readOnly && <HtmlEditorPowerUps onAttributeSelected={addVariable} />}
        </Layout.Group>
      </StepEditorAccordion>
    </Block>
  );
};
