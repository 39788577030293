import { DataForm, ErrorMessage, Field, Layout } from "@introist/react-foundation/v2";

import { Block } from "components/atoms";

import { StepEditorAccordion } from "modules/workflows/routes/WorkflowEditor/StepEditorAccordion";
import { MessageEditor } from "../../../../../../../components/editors/MessageEditor";
import { AttributeInput } from "../../../../../../../components/molecules";
import { EmployeeField } from "../../../../../../../services/rpc/RpcProvider";

type EmailContentBlockProps = {
  form: DataForm<any>;
  formKeyPrefix: string;
  fields?: EmployeeField[];
};

export const EmailContentBlock = ({
  form,
  formKeyPrefix,
  fields,
  ...rest
}: EmailContentBlockProps) => {
  const getFullKey = (key: string) => `${formKeyPrefix || ""}${key}`;

  const messageError = form.error(getFullKey("message"));
  const hasErrors = form.isError(getFullKey("subject")) || !!messageError;

  return (
    <Block compact blended {...rest}>
      <StepEditorAccordion
        error={hasErrors ? "Invalid fields" : undefined}
        title="Message"
        defaultOpen={true}
      >
        <Layout.Group vertical gap="xLarge">
          <Field title="Subject">
            <AttributeInput
              size="small"
              placeholder="Email subject"
              value={form.get(getFullKey("subject"))}
              onChange={form.set(getFullKey("subject"))}
              error={form.isError(getFullKey("subject"))}
            />
          </Field>
          <Field title="Message">
            <MessageEditor
              message={form.get(getFullKey("message"))}
              onChange={form.set(getFullKey("message"))}
              variables={(fields ?? []).map(f => ({ key: f.variable, title: f.name }))}
            />
            {messageError && <ErrorMessage>{messageError}</ErrorMessage>}
          </Field>
        </Layout.Group>
      </StepEditorAccordion>
    </Block>
  );
};
