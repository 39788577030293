import { DataForm, InfoCard, Select, Layout } from "@introist/react-foundation/v2";

import { Block } from "components/atoms";
import { prefixForm } from "utils/FormUtils";
import { WorkflowStep } from "services/api/WorkflowApi";
import { StepEditorAccordion } from "modules/workflows/routes/WorkflowEditor/StepEditorAccordion";
import { InstructionEmailEditor } from "./blocks/InstructionEmailEditor";
import { useAttributes } from "../../../../../../hooks/use-attributes";

type CreateGoogleAccountEditorV2Props = {
  form: DataForm<WorkflowStep>;
  formPrefix?: string;
};

export const CreateGoogleAccountEditorV3 = ({
  form,
  formPrefix
}: CreateGoogleAccountEditorV2Props) => {
  const prefixedForm = prefixForm(form, formPrefix);
  const emailAttributes = useAttributes(false, ["email"]);

  return (
    <>
      <Block compact blended>
        <StepEditorAccordion title="Google account details">
          <Layout.Group vertical gap="xLarge">
            <InfoCard
              hideIcon
              title="An account will be created for the employee using email address found from the selected attribute."
              colorVariant="primary"
            />
            <StepEditorAccordion.Row label="Email">
              <Select
                variant="blended"
                size="small"
                element="button"
                options={emailAttributes.map(attr => ({ key: attr.variable, title: attr.name }))}
                attachToRef={false}
                style={{ width: "100%" }}
                placeholder="Select account email address"
                value={prefixedForm.get("emailAttribute")}
                onSelect={opt => prefixedForm.set("emailAttribute")(opt.key)}
                error={prefixedForm.isError("emailAttribute")}
              />
            </StepEditorAccordion.Row>
          </Layout.Group>
        </StepEditorAccordion>
      </Block>
      <InstructionEmailEditor prefixedForm={prefixedForm} />
    </>
  );
};
