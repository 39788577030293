import { useMemo } from "react";
import * as d3 from "d3";
import { useTheme } from "@introist/react-foundation/v2";

type Props = {
  domain: Date[];
  range: number[];
  boundedHeight: number;
};

export const CurrentDateMarker = ({ domain, range, boundedHeight, ...rest }: Props) => {
  const { theme } = useTheme();
  const currentDateOffset = useMemo(() => {
    const now = new Date();
    const xScale = d3.scaleUtc().domain(domain).range(range);
    return xScale(now);
  }, [domain, range]);

  return (
    <g className="current-date-marker" transform={`translate(${currentDateOffset}, 0)`}>
      <line
        y2={boundedHeight}
        stroke={theme.palette.foreground.dimmed}
        strokeWidth={1.5}
        strokeDashoffset={3}
        strokeDasharray={3}
      />
      <text
        y={boundedHeight - 6} // Adjust based on your needs
        x={6}
        style={{
          fontSize: 10,
          textAnchor: "start",
          fontFamily: "Poppins",
          fill: theme.palette.foreground.subdued
        }}
      >
        Today
      </text>
    </g>
  );
};
