import { CategorisedOption, Stack, TableActionBar } from "@introist/react-foundation/v2";
import { JourneysTimelineItem, JourneysTimeline, JourneysTimelineLoader } from "./JourneysTimeline";
import { useMemo, useState } from "react";
import useMeasure from "react-use-measure";
import { api } from "services/rpc/RpcProvider";
import {
  JOURNEYS_TIMELINE_FILTERS,
  buildJourneysTimelineFilterOptions,
  filterJourneysTimeline
} from "./filters";
import { Empty } from "../../../../foundation";

export const AutomationsTimeline = () => {
  const journeyTimeline = api.journeys.v2.list.useQuery({});
  const workflows = api.workflows.v2.list.useQuery({});

  const [graphContainerRef, { width }] = useMeasure();

  const [filters, setFilters] = useState<CategorisedOption[]>([]);

  const timelineData: JourneysTimelineItem[] = useMemo(() => {
    return (journeyTimeline.data || []).map(j => {
      return {
        ...j,
        employeeName: j.employeeName || "Deleted Employee"
      };
    });
  }, [journeyTimeline.data]);

  const filteredTimelineData = filterJourneysTimeline(timelineData, filters);

  return (
    <Stack vertical>
      <TableActionBar
        title="Timeline"
        description={!journeyTimeline.isLoading ? filteredTimelineData.length.toString() : "..."}
        filter={{
          options: buildJourneysTimelineFilterOptions(timelineData, workflows.data || []),
          categories: JOURNEYS_TIMELINE_FILTERS,
          value: filters,
          onFilterChange: setFilters
        }}
      />
      <Stack vertical>
        {journeyTimeline.isLoading ? (
          <JourneysTimelineLoader />
        ) : (
          <div style={{ position: "relative" }}>
            <div
              ref={graphContainerRef}
              style={{
                width: "100%",
                position: "absolute"
              }}
            >
              {filteredTimelineData.length > 0 ? (
                <JourneysTimeline data={filteredTimelineData} maxWidth={width} />
              ) : (
                <Empty icon="list" title="No active automations" message="" />
              )}
            </div>
          </div>
        )}
      </Stack>
    </Stack>
  );
};
