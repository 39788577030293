import { useCallback, useMemo } from "react";
import { config } from "../../../Config";
import { ConnectedAccount, api } from "../../../services/rpc/RpcProvider";
import { CONNECTED_ACCOUNTS_ROUTES } from "AppRoutes";

const providerAccounts = (accounts: ConnectedAccount[] = [], provider: string) => {
  return accounts.filter(a => a.emailProvider === provider);
};

export const useConnectAccount = () => {
  const { data: workspace } = api.workspaces.find.useQuery({});

  const connectedAccountsApi = api.connectedAccounts;

  const {
    data: connectedAccounts,
    refetch: refetchConnectedAccounts,
    isLoading: isLoadingConnectedAccounts
  } = connectedAccountsApi.list.useQuery({});

  const { useMutation: deleteConnectedAccount } = connectedAccountsApi.delete;

  const getPublicAccountConnectUrl = useCallback(
    (provider: string) => {
      return `${config.appBaseUrl}${CONNECTED_ACCOUNTS_ROUTES.publicConnectStart.path.replace(
        ":workspaceToken",
        workspace?.id ?? "unknown"
      )}?provider=${provider}`;
    },
    [workspace]
  );

  const googleAccounts = useMemo(
    () => providerAccounts(connectedAccounts, "google"),
    [connectedAccounts]
  );

  const microsoftAccounts = useMemo(
    () => providerAccounts(connectedAccounts, "outlook"),
    [connectedAccounts]
  );

  const slackAccounts = useMemo(
    () => providerAccounts(connectedAccounts, "slack"),
    [connectedAccounts]
  );

  return {
    getPublicAccountConnectUrl,
    deleteConnectedAccount,
    connectedAccounts,
    googleAccounts,
    microsoftAccounts,
    slackAccounts,
    isLoadingConnectedAccounts,
    refetchConnectedAccounts
  };
};
