import { useMemo } from "react";
import { DataForm } from "@introist/react-foundation/v2";
import { WorkflowStep } from "services/api/WorkflowApi";

// Editors
import { EmailStepEditor } from "../../EmailStepEditor/EmailStepEditor";
import { EmailFormEditorV2 } from "../../EmailFormEditorV2";
import { EmailTaskEditorV2, SlackTaskEditor } from "../../TaskStepEditorV2";
import { SlackStepEditorV2 } from "../../SlackStepEditorV2";
import { CreateGoogleAccountEditorV2 } from "../../CreateGoogleAccountEditorV2";
import { EventStepEditor } from "../../EventStepEditor";
import { WebhookStepEditorV2 } from "../../WebhookStepEditorV2";
import { WorkflowTriggerStepEditorV2 } from "../../WorkflowTriggerStepEditorV2";

import { InviteStepEditor } from "../../InviteStepEditor";
import { SlackInviteToChannelEditor } from "../../SlackInviteToChannelEditor";
import { CreateGoogleAccountEditorV3 } from "../../CreateGoogleAccountEditorV3";
import { StepTypesV2 } from "services/rpc/RpcProvider";
import { FreshserviceOnboardingEditor } from "../../FreshserviceOnboarding";
import { AttendToEventEditor } from "../../AttendToEventEditor";
import { SlackQuestionEditor } from "../../SlackQuestionEditor";
import { EmployeeSelectEditor } from "../../EmployeeSelectEditor";
import { WorkflowTriggerStepEditorV3 } from "../../WorkflowTriggerStepEditorV3";
import { CreateAccountStepEditor } from "../../CreateAccount";

type UseStepEditorArgs = {
  form: DataForm<WorkflowStep>;
  formPrefix: string;
  targetDateTerm?: string;
};

export const useWorkflowStepEditor = ({ form, formPrefix, targetDateTerm }: UseStepEditorArgs) => {
  const editor = useMemo(() => {
    const { Enum } = StepTypesV2;
    switch (form.data.stepType) {
      case Enum.creategoogleaccount:
        return <CreateGoogleAccountEditorV2 form={form} formPrefix={formPrefix} />;
      case Enum.creategoogle:
        return <CreateGoogleAccountEditorV3 form={form} formPrefix={formPrefix} />;
      case Enum.email:
        return <EmailStepEditor form={form} />;
      case Enum.emailform:
        return <EmailFormEditorV2 form={form} />;
      case Enum.emailtask:
        return <EmailTaskEditorV2 form={form} />;
      case Enum.enroll:
        return <WorkflowTriggerStepEditorV2 form={form} formPrefix={formPrefix} />;
      case Enum.event:
        return <EventStepEditor form={form} formPrefix={formPrefix} />;
      case Enum.invite:
        return <InviteStepEditor form={form} formPrefix={formPrefix} />;
      case Enum.slack:
        return <SlackStepEditorV2 form={form} formPrefix={formPrefix} />;
      case Enum.slacktask:
        return <SlackTaskEditor form={form} formPrefix={formPrefix} />;
      case Enum.webhook:
        return <WebhookStepEditorV2 form={form} formPrefix={formPrefix} />;
      case Enum.slackchannelinvite:
        return <SlackInviteToChannelEditor form={form} />;
      case Enum.freshserviceonboarding:
        return <FreshserviceOnboardingEditor form={form} formPrefix={formPrefix} />;
      case Enum.eventattend:
        return (
          <AttendToEventEditor
            form={form}
            formPrefix={formPrefix}
            targetDateTerm={targetDateTerm}
          />
        );
      case Enum.slackquestion:
        return <SlackQuestionEditor form={form} formPrefix={formPrefix} />;
      case Enum.employeeselect:
        return <EmployeeSelectEditor form={form} formPrefix={formPrefix} />;
      case Enum.workflowtrigger:
        return <WorkflowTriggerStepEditorV3 form={form} formPrefix={formPrefix} />;
      case Enum.createaccount:
        return (
          <CreateAccountStepEditor
            stepPayload={form.data.stepData}
            onChange={(field, value) => form.set(`stepData.${field}`)(value)}
          />
        );
      default:
        return null;
    }
  }, [form, formPrefix, targetDateTerm]);

  return editor;
};
