import { useMemo } from "react";
import { Field, InfoCard, Layout, Input, Title, Toggle } from "@introist/react-foundation/v2";

import { HtmlEditor } from "components/organisms";

import { Block } from "components/atoms";
import { StepEditorAccordion } from "modules/workflows/routes/WorkflowEditor/StepEditorAccordion";

import { PrefixedForm } from "utils/FormUtils";
import { useAttributes, useEmailSenders } from "hooks";
import { EmailAddressSingleSelect } from "modules/workflows/components/EmailAddressSingleSelect";

type InstructionEmailEditorProps = {
  prefixedForm: PrefixedForm;
};

export const InstructionEmailEditor = ({ prefixedForm }: InstructionEmailEditorProps) => {
  const { senders, isLoading: isSendersLoading } = useEmailSenders();

  const emailAttributes = useAttributes(false, ["email"]);

  const senderOptions = useMemo(() => {
    return senders.map(sender => ({
      key: sender.id,
      title: sender.name,
      description: sender.email
    }));
  }, [senders]);

  const selectedSender = useMemo(() => {
    return senderOptions.find(
      opt => opt.key === prefixedForm.get("instructionMessageTemplate.from")
    );
  }, [senderOptions, prefixedForm]);

  const toOptions = useMemo(() => {
    return emailAttributes.map(attr => ({
      key: attr.variable,
      title: attr.name
    }));
  }, [emailAttributes]);

  const selectedTo = useMemo(() => {
    const val = prefixedForm.get("instructionMessageTemplate.to");
    if (val.length > 0) return toOptions.find(opt => opt.key === val[0]);
  }, [toOptions, prefixedForm]);

  return (
    <Block blended compact>
      <StepEditorAccordion title="Initial login information" defaultOpen={false}>
        <Layout.Group vertical gap="xLarge">
          <Layout.Group>
            <Toggle
              checked={prefixedForm.get("sendInstructions")}
              onChange={prefixedForm.set("sendInstructions")}
            />
            <Title>Send instructions email</Title>
          </Layout.Group>

          {prefixedForm.get("sendInstructions") && (
            <>
              <InfoCard
                hideIcon
                colorVariant="primary"
                title="The initial login information, including an automatically generated password, will be sent to the employee using the following message. Please ensure that the variables {{username}} and {{password}} are included in the message."
              />
              <StepEditorAccordion.Row label="From">
                {!isSendersLoading && (
                  <EmailAddressSingleSelect
                    initialOptions={senderOptions}
                    initialSelectedOption={selectedSender}
                    addButtonText="Add sender"
                    error={
                      prefixedForm.isError("instructionMessageTemplate.from")
                        ? "Required"
                        : undefined
                    }
                    onChange={selectedId =>
                      prefixedForm.set("instructionMessageTemplate.from")(selectedId)
                    }
                  />
                )}
              </StepEditorAccordion.Row>
              <StepEditorAccordion.MultiOptionRow label="To">
                <EmailAddressSingleSelect
                  initialOptions={toOptions}
                  initialSelectedOption={selectedTo}
                  onChange={val => prefixedForm.set("instructionMessageTemplate.to")([val])}
                />
              </StepEditorAccordion.MultiOptionRow>

              <Field title="Subject">
                <Input
                  size="small"
                  placeholder="Message subject"
                  value={prefixedForm.get("instructionMessageTemplate.subject")}
                  onChange={prefixedForm.set("instructionMessageTemplate.subject")}
                />
              </Field>
              <Field title="Message">
                <HtmlEditor
                  defaultValue={prefixedForm.get("instructionMessageTemplate.message")}
                  onChange={prefixedForm.set("instructionMessageTemplate.message")}
                />
              </Field>
            </>
          )}
        </Layout.Group>
      </StepEditorAccordion>
    </Block>
  );
};
