import { StepEditorProps } from "../useStepEditor";
import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { Select, Toggle, useTheme } from "@introist/react-foundation/v2";
import { Block } from "../../../../components/atoms";
import { prefixForm } from "../../../../utils/FormUtils";

import { useWorkflows } from "../../hooks/useWorkflows";

export const WorkflowTriggerStepEditor = ({ form, stepDataPath }: StepEditorProps) => {
  const { theme } = useTheme();
  const prefixedForm = prefixForm(form, stepDataPath);

  const { workflows } = useWorkflows({});

  return (
    <Block blended compact>
      <StepEditorAccordion title="Workflow details" stepOrder={2}>
        <StepEditorAccordion.Row label="Workflow">
          <Select
            attachToRef={false}
            element="button"
            variant="blended"
            searchable
            searchPlaceholder="Find workflow"
            placeholder="Select workflow to trigger"
            value={prefixedForm.get("workflowId")}
            options={workflows?.map(wf => ({ key: wf.id, title: wf.title })) || []}
            onSelect={option => prefixedForm.set("workflowId")(option.key)}
            error={prefixedForm.isError("workflowId")}
          />
        </StepEditorAccordion.Row>

        <StepEditorAccordion.Row label="Start as draft">
          <Toggle
            style={{ marginLeft: theme.spacing.medium }}
            checked={prefixedForm.get("startAsDraft")}
            onChange={prefixedForm.set("startAsDraft")}
          />
        </StepEditorAccordion.Row>
      </StepEditorAccordion>
    </Block>
  );
};
