import { useEffect, useMemo, useState } from "react";

import { DataForm, Select, Stack, TextButton } from "@introist/react-foundation/v2";
import { useEmailSenders } from "hooks/use-email-senders";
import { Block } from "components/atoms";

import { WorkflowStep } from "services/api/WorkflowApi";
import { JourneyStepV2 } from "services/rpc/RpcProvider";

import { StepEditorAccordion } from "modules/workflows/routes/WorkflowEditor/StepEditorAccordion";

import { prefixForm } from "../../../../utils/FormUtils";
import { EmailAddressList } from "./EmailAddressList";
import { isArray } from "lodash";
import { useEmployeeFields } from "../../../employees/hooks/useEmployeeFields";
import { FilledIcon } from "./FilledIcon";

type EmailAddressesBlockProps = {
  form: DataForm<WorkflowStep> | DataForm<JourneyStepV2>;
  formKeyPrefix?: string;
};

const array = (val?: any) => {
  if (isArray(val)) return val;
  return [];
};

export const EmailStepAddressBlock = ({ form, formKeyPrefix }: EmailAddressesBlockProps) => {
  const [replyToActive, setReplyToActive] = useState(false);
  const [ccActive, setCcActive] = useState(false);
  const [bccActive, setBccActive] = useState(false);

  const { fields } = useEmployeeFields();
  const { senders } = useEmailSenders();

  const prefixedForm = prefixForm(form, formKeyPrefix);

  useEffect(() => {
    if (prefixedForm.get("replyTo") && prefixedForm.get("replyTo") !== "" && !replyToActive)
      setReplyToActive(true);
    if (array(prefixedForm.get("cc")).length > 0 && !ccActive) setCcActive(true);
    if (array(prefixedForm.get("bcc")).length > 0 && !bccActive) setBccActive(true);
  }, [prefixedForm, replyToActive, ccActive, bccActive]);

  const senderOptions = useMemo(() => {
    const connectedSenderOptions = senders.map(sender => ({
      key: sender.id,
      title: sender.email,
      description: sender.name,
      startAdornment: <FilledIcon name="person" />
    }));

    const dynamicSenderOptions = (fields ?? [])
      .filter(f => f.type === "email")
      .map(f => ({ key: f.key, title: f.title, startAdornment: <FilledIcon name="attribute" /> }));

    return [...connectedSenderOptions, ...dynamicSenderOptions];
  }, [senders, fields]);

  const showAccordionError =
    prefixedForm.isError("emailSenderId") ||
    prefixedForm.isError("replyTo") ||
    prefixedForm.isError("to") ||
    prefixedForm.isError("cc") ||
    prefixedForm.isError("bcc");

  return (
    <Block blended compact>
      <StepEditorAccordion
        title="Sender & Receivers"
        error={showAccordionError ? "Invalid fields" : undefined}
      >
        <Stack vertical alignItems="stretch" gap="large">
          <StepEditorAccordion.Row label="From">
            <Select
              searchable
              size="small"
              variant="blended"
              placeholder="Select sender"
              options={senderOptions}
              value={prefixedForm.get("emailSenderId")}
              onSelect={opt => prefixedForm.set("emailSenderId")(opt.key)}
              attachToRef={false}
              style={{ width: "100%" }}
              startAdornment={
                (fields ?? []).some(f => f.key === prefixedForm.get("emailSenderId")) ? (
                  <FilledIcon name="attribute" />
                ) : (
                  <FilledIcon name="person" />
                )
              }
            />
          </StepEditorAccordion.Row>

          {replyToActive && (
            <StepEditorAccordion.Row label="Reply to">
              <EmailAddressList
                value={prefixedForm.get("replyTo") ? [prefixedForm.get("replyTo")] : []}
                onChange={val => {
                  prefixedForm.set("replyTo")(val.length > 0 ? val[0] : null);
                }}
                placeholder="Add reply to"
                single
              />
            </StepEditorAccordion.Row>
          )}

          <StepEditorAccordion.MultiOptionRow label="To">
            <EmailAddressList
              value={isArray(prefixedForm.get("to")) ? prefixedForm.get("to") : []}
              onChange={prefixedForm.set("to")}
              placeholder="Add to"
            />
          </StepEditorAccordion.MultiOptionRow>

          {ccActive && (
            <StepEditorAccordion.MultiOptionRow label="Cc">
              <EmailAddressList
                value={isArray(prefixedForm.get("cc")) ? prefixedForm.get("cc") : []}
                onChange={prefixedForm.set("cc")}
                placeholder="Add cc"
              />
            </StepEditorAccordion.MultiOptionRow>
          )}
          {bccActive && (
            <StepEditorAccordion.MultiOptionRow label="Bcc">
              <EmailAddressList
                value={isArray(prefixedForm.get("bcc")) ? prefixedForm.get("bcc") : []}
                onChange={prefixedForm.set("bcc")}
                placeholder="Add bcc"
              />
            </StepEditorAccordion.MultiOptionRow>
          )}

          <Stack justifyContent="flex-end">
            {!replyToActive && (
              <TextButton onClick={() => setReplyToActive(true)}>Reply to</TextButton>
            )}
            {!ccActive && <TextButton onClick={() => setCcActive(true)}>Cc</TextButton>}
            {!bccActive && <TextButton onClick={() => setBccActive(true)}>Bcc</TextButton>}
          </Stack>
        </Stack>
      </StepEditorAccordion>
    </Block>
  );
};
