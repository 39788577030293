import { useCallback, useMemo } from "react";
import sortBy from "lodash.sortby";

import { useEmployeeAttributeApi } from "modules/employees/api/EmployeeAttributeApi";
import { useSlackSenderApi } from "services/api/SlackSenderApi";
import { EmailAddressMultiSelect } from "modules/workflows/components/EmailAddressMultiSelect";

type SlackUsersSelectV2Props = {
  senderId?: string;
  initialSelected: string[];
  error?: boolean;
  onSelect: (newValue: string[]) => unknown;
  readOnly?: boolean;
  journeyAttributes?: { [key: string]: string };
  addButtonText?: string;
  isJourneyEditor?: boolean;
};

export const SlackUserSelectV2 = ({
  senderId,
  initialSelected,
  error,
  onSelect,
  readOnly,
  isJourneyEditor,
  journeyAttributes,
  addButtonText
}: SlackUsersSelectV2Props) => {
  const slackSenderApi = useSlackSenderApi();
  const { data: slackUsers, isLoading: isUsersLoading } = slackSenderApi.listUsers(
    { senderId: senderId || "" },
    { enabled: !!senderId }
  ).query;

  const attributeApi = useEmployeeAttributeApi();
  const { data: properties, isLoading: isPropertiesLoading } = attributeApi.list({
    includeHidden: false
  }).query;

  const getEmailPropertyOptions = useCallback(() => {
    if (!properties) return [];
    const options = properties
      .filter(prop => prop.type === "email")
      .map(prop => ({
        key: prop.variable,
        title: prop.name,
        description: journeyAttributes ? journeyAttributes[prop.variable] : undefined
      }));
    return sortBy(options, "title");
  }, [journeyAttributes, properties]);

  const options = useMemo(() => {
    if (isPropertiesLoading || isUsersLoading) return [];
    const propertyOptions = getEmailPropertyOptions();
    const userOptions =
      slackUsers?.map(user => ({ key: user.id, title: user.realName, description: "" })) || [];

    return [...propertyOptions, ...userOptions];
  }, [isPropertiesLoading, isUsersLoading, slackUsers, getEmailPropertyOptions]);

  const selectedOptions = useMemo(() => {
    return options.filter(option => initialSelected.find(id => option.key === id));
  }, [options, initialSelected]);

  return (
    <EmailAddressMultiSelect
      isJourneyEditor={isJourneyEditor}
      readOnly={readOnly}
      initialOptions={options}
      onChange={nextSelected => onSelect(nextSelected)}
      addButtonText={addButtonText ?? "Add receiver"}
      initialSelectedOptions={selectedOptions}
      error={error ? "Required field" : undefined}
      addButtonReadOnlyTooltip="Start by selecting a sender"
    />
  );
};
