import { CategorisedOption, Icon } from "@introist/react-foundation/v2";
import { JourneysTimelineItem } from "./JourneysTimeline";
import { uniqBy } from "lodash";
import { WorkflowOverviewV2 } from "services/rpc/RpcProvider";

export const JOURNEYS_TIMELINE_FILTERS = [
  {
    title: "Employee",
    key: "employee",
    startAdornment: <Icon name="person" />
  },
  {
    title: "Workflow",
    key: "workflow",
    startAdornment: <Icon name="workflows" />
  }
];

export const buildJourneysTimelineFilterOptions = (
  timelineItem: JourneysTimelineItem[],
  workflows: WorkflowOverviewV2[]
) => {
  const uniqueEmployeees = uniqBy(timelineItem, "employeeId");
  const employeeOptions: CategorisedOption[] = uniqueEmployeees.map((i, idx) => ({
    key: i.employeeId || `deleted-employee--${idx}`,
    title: i.employeeName || "Deleted Employee",
    category: "employee"
  }));

  const workflowOptions: CategorisedOption[] = workflows.map(w => ({
    key: w.id,
    title: w.name,
    category: "workflow"
  }));

  return [...employeeOptions, ...workflowOptions];
};

export const filterJourneysTimeline = (
  timelineItems: JourneysTimelineItem[],
  filters: CategorisedOption[]
) => {
  let filteredTimelineItems = timelineItems;
  filteredTimelineItems = filterByEmployee(timelineItems, filters);
  filteredTimelineItems = filterByWorkflows(filteredTimelineItems, filters);

  return filteredTimelineItems;
};

const filterByEmployee = (timelineItem: JourneysTimelineItem[], filters: CategorisedOption[]) => {
  const employeeFilters = selectedFilters(filters, "employee");
  if (employeeFilters.length === 0) return timelineItem;

  return timelineItem.filter(t => {
    return employeeFilters.includes(t.employeeId || "deleted-employee");
  });
};

const filterByWorkflows = (timelineItem: JourneysTimelineItem[], filters: CategorisedOption[]) => {
  const workflowFilters = selectedFilters(filters, "workflow");
  if (workflowFilters.length === 0) return timelineItem;

  return timelineItem.filter(t => {
    return workflowFilters.includes(t.workflow.id);
  });
};

const selectedFilters = (filters: CategorisedOption[], category: string) => {
  return filters.filter(f => f.category === category).map(f => f.key);
};
