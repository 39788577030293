import { IntroistApiTypes } from "@introist/introist-api-types";

import { createTRPCProxyClient, createTRPCReact, httpLink } from "@trpc/react-query";
import { ComponentProps, useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { config } from "../../Config";
import { inferRouterInputs, inferRouterOutputs } from "@trpc/server";
import { authHeaders } from "../api/ApiBase";
import { z } from "zod";

const createLinks = () => {
  return [
    httpLink({
      url: `${config.apiUrl}/rpc`,
      headers: async () => authHeaders()
    })
  ];
};

export const api = createTRPCReact<IntroistApiTypes>();

export const apiClient = createTRPCProxyClient<IntroistApiTypes>({
  links: createLinks()
});

export const RpcProvider = (props: ComponentProps<"div">) => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnMount: true,
            refetchOnWindowFocus: false,
            staleTime: 1000 * 60,
            retry: false
          }
        }
      })
  );
  const [trpcClient] = useState(() =>
    api.createClient({
      links: createLinks()
    })
  );

  return (
    <api.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{props.children}</QueryClientProvider>
    </api.Provider>
  );
};

export type RouterInput = inferRouterInputs<IntroistApiTypes>;
export type RouterOutput = inferRouterOutputs<IntroistApiTypes>;

export type NotificationScheme = RouterOutput["notifications"]["schemes"]["list"][0];
export type NotificationTopic = RouterOutput["notifications"]["schemes"]["list"][0]["topic"];
export type Organisation = RouterOutput["workspaces"]["find"];

export const StepTypesV2 = z.enum([
  "event",
  "webhook",
  "slack",
  "creategoogleaccount",
  "creategoogle",
  "invite",
  "emailtask",
  "slacktask",
  "emailform",
  "email",
  "trelloboard",
  "enroll",
  "slackchannelinvite",
  "freshserviceonboarding",
  "eventattend",
  "slackquestion",
  "employeeselect",
  "workflowtrigger",
  "createaccount"
]);
export type StepTypeV2 = z.infer<typeof StepTypesV2>;
export type StepTypeWithEventTypeV2 =
  | "individual-event"
  | "common-event"
  | "meeting"
  | Exclude<StepTypeV2, "event">;

export type JourneyStepV2 = RouterOutput["journeys"]["steps"]["find"] & {
  previewData?: any;
  journeyData?: any;
  stepType: StepTypeV2;
  scheduledAt: Date;
  deliveredAt?: Date;
  id: string;
  journeyId: string;
  errors?: string[];
  status: string;
  remarks?: string;
  cutoffDays: number;
};

export type JourneyStepStatusV2 = RouterOutput["journeys"]["steps"]["find"]["status"];

export type WorkflowOverviewV2 = RouterOutput["workflows"]["list"][0];

export type WorkflowCategory = RouterOutput["workflows"]["list"][0]["category"];

export type Material = RouterOutput["materials"]["list"][0];
export type HrisDetails = NonNullable<RouterOutput["hris"]["details"]>;
export type HrisEmployeeOverview = NonNullable<RouterOutput["hris"]["employees"]["search"][0]>;
export type HrisField = RouterOutput["hris"]["fields"][0];

export type ConnectedAccount = RouterOutput["connectedAccounts"]["list"][0];

export type WorkspaceUser = RouterOutput["users"]["list"][0];
export type TUserRole = WorkspaceUser["role"];

export type JourneyOverview = RouterOutput["journeys"]["v2"]["list"][0];

export type ConnectDataSourceInput = RouterInput["employees"]["dataSources"]["create"];

export type CompanyEvent = RouterOutput["companyEvents"]["list"][0];
export type CompanyEventSetting = RouterOutput["companyEvents"]["settings"]["list"][0];

type UpcomingComapanyEventSettingKey =
  | "CancelSessionOnPresenterDecline"
  | "RescheduleOnWeekendOrHoliday"
  | "BookMeetingRoom"
  | "RemindAttendeeOnWaiting"
  | "VerifyAttendance";
export type CompanyEventSettingKey =
  | RouterOutput["companyEvents"]["settings"]["list"][0]["setting"]
  | UpcomingComapanyEventSettingKey;

export type CompanyEventInstance = RouterOutput["companyEvents"]["instances"]["list"][0];
export type ManagedEventAttendee = RouterOutput["companyEvents"]["attendees"]["list"][0];
export type CompanyEventInstanceAttendee =
  RouterOutput["companyEvents"]["instances"]["list"][0]["attendees"][0];
export type CompanyEventInstanceAttendeeHistoryEntry =
  RouterOutput["companyEvents"]["attendees"]["history"][0];

export type CompanyEventPreviewImportEmployee = RouterOutput["companyEvents"]["previewImport"][0];

export type AccountCalendar = RouterOutput["companyEvents"]["connect"]["authorize"]["calendars"][0];
export type CalendarEventList = RouterOutput["companyEvents"]["calendars"]["listEvents"];
export type CalendarEvent = RouterOutput["companyEvents"]["calendars"]["listEvents"]["events"][0];

export type UnmanagedEventAttendee = RouterOutput["companyEvents"]["attendees"]["listUnmanaged"][0];

export type EmployeeGroup = RouterOutput["employees"]["groups"]["list"][0];
export type EmployeeGroupInput = RouterInput["employees"]["groups"]["create"];

export type EmployeeField = RouterOutput["employees"]["fields"]["list"][0];
export type GroupedEmployeeField = RouterOutput["employees"]["fields"]["list"][0];
export type ComputedEmployeeField = RouterOutput["employees"]["fields"]["computed"]["list"][0];

export type EmployeeFieldType = RouterOutput["employees"]["fields"]["list"][0]["type"];

export type SsoConfig = NonNullable<RouterOutput["workspaces"]["sso"]["find"]>;
export type SsoConfigUpdate = RouterInput["workspaces"]["sso"]["update"];

export type EmployeeDataSource = RouterOutput["employees"]["dataSources"]["list"][0];
export type EmpDataSource = RouterOutput["employees"]["dataSources"]["find"];

export type DataSourceTestResult = RouterOutput["employees"]["dataSources"]["test"];

export type DataSourceSyncMode = RouterOutput["employees"]["dataSources"]["list"][0]["syncMode"];

export type DataSourceMappingEntry =
  RouterOutput["employees"]["dataSources"]["mappings"]["list"][0];

export type SyncTestEmployeeData = RouterOutput["employees"]["dataSources"]["testEmployee"];

export type WorkflowFolderV2 = RouterOutput["workflows"]["v2"]["folders"]["find"];
export type Trigger = RouterOutput["triggers"]["find"];
export type TriggerPreview = RouterOutput["triggers"]["preview"][0];

export type Holiday = RouterOutput["holidayCalendar"]["find"];

export type Journey = RouterOutput["journeys"]["v2"]["list"][0];

export type DatasetDef = RouterOutput["datasets"]["defs"]["find"];
export type DatasetDefCreate = RouterInput["datasets"]["defs"]["create"];
export type DatasetDefUpdate = RouterInput["datasets"]["defs"]["update"];

export type WorkflowStepAction = RouterInput["workflows"]["v4"]["steps"]["action"]["action"];
