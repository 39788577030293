import { StepEditorProps } from "../useStepEditor";

import { Block } from "../../../../components/atoms";
import { prefixForm } from "../../../../utils/FormUtils";
import { Icon, Select } from "@introist/react-foundation/v2";
import { StepEditorAccordion } from "modules/workflows/routes/WorkflowEditor/StepEditorAccordion";

export const DoceboLearningPlanEditor = ({ form, stepDataPath }: StepEditorProps) => {
  const prefixedForm = prefixForm(form, stepDataPath);

  const onChanged = (lpId: string) => {
    prefixedForm.set("learningPlanId")(lpId);
  };

  return (
    <>
      <Block compact blended>
        <StepEditorAccordion title="Learning Plan" defaultOpen={true}>
          <StepEditorAccordion.Row label="Learning Plan">
            <Select
              element="button"
              variant="blended"
              placeholder="Select channel"
              startAdornment={
                !prefixedForm.get("learningPlanId") ? (
                  <Icon name="plusSmall" danger={prefixedForm.isError("learningPlanId")} />
                ) : (
                  <Icon name="book" />
                )
              }
              options={[
                { key: "1", title: "Security" },
                { key: "2", title: "Manager Training" }
              ]}
              value={prefixedForm.get("learningPlanId")}
              attachToRef={false}
              searchable
              onSelect={opt => onChanged(opt.key)}
              error={prefixedForm.isError("learningPlanId")}
            />
          </StepEditorAccordion.Row>
        </StepEditorAccordion>
      </Block>
    </>
  );
};
