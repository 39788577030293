import { ListView, TopBarRoute } from "../../../components/templates";

import { Avatar, TabBar } from "../../../components/molecules";
import { Icon, Stack, Title } from "@introist/react-foundation/v2";
import { useSearch, useStoredState } from "../../../foundation";
import { z } from "zod";
import { AutomationsTimeline } from "../views/AutomationsTimeline";
import { UpcomingSteps } from "../views/UpcomingSteps";
import { useNavigate } from "react-router-dom";
import { ListRow } from "../../../components/organisms";
import { AutomationStage, useAutomations } from "../hooks/useAutomations";
import styled from "styled-components";

export const AutomationsRoute = () => {
  const [tab, setTab] = useStoredState<string>("intr.automations.tab", "active", z.string());

  return (
    <TopBarRoute paths={[{ key: "automations", title: "Automations" }]}>
      <Stack vertical>
        <div style={{ marginRight: "auto" }}>
          <TabBar
            tabs={[
              { id: "active", title: "Active", iconName: "trigger" },
              { id: "draft", title: "Drafts", iconName: "progress" },
              { id: "timeline", title: "Timeline", iconName: "list" },
              { id: "steps", title: "Steps", iconName: "stepType" }
            ]}
            activeTab={tab}
            onTabChange={setTab}
          />
        </div>

        {tab === "active" && <AutomationsList stage="inprogress" title="Active automations" />}
        {tab === "draft" && <AutomationsList stage="draft" title="Drafts" />}
        {tab === "timeline" && <AutomationsTimeline />}
        {tab === "steps" && <UpcomingSteps />}
      </Stack>
    </TopBarRoute>
  );
};

const NoOverflowTitle = styled(Title)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

type AutomationsListProps = {
  stage: AutomationStage;
  title?: string;
};

export const AutomationsList = ({ stage, title = "Automations" }: AutomationsListProps) => {
  const navigate = useNavigate();

  const { automations } = useAutomations({ stages: [stage!] });

  const { filtered, search, setSearch } = useSearch(automations, {
    fields: ["employeeName", "title"]
  });

  return (
    <Stack vertical gap="large">
      <ListView.Header
        title={title}
        count={filtered?.length}
        search={{
          search,
          setSearch
        }}
      />
      <ListView.List
        data={filtered}
        renderRow={j => (
          <ListRow
            gridTemplateColumns="25px 200px 1fr 265px"
            onClick={() => navigate(`/automations/${j.id}`)}
          >
            <Avatar nameOrEmail={j.employeeName ?? "Deleted employee"} />
            <NoOverflowTitle>{j.employeeName ?? "Deleted employee"}</NoOverflowTitle>
            <Title variant="bold">{j.title}</Title>
            <Stack justifyContent="flex-end">
              {j.status === "failing" && <Icon name="warning" danger />}
            </Stack>
          </ListRow>
        )}
      />
    </Stack>
  );
};
