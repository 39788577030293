import { useState } from "react";
import styled from "styled-components";

import { Modal, ModalProps, Layout, H4 } from "@introist/react-foundation/v2";

import { TextInput } from "components/atoms";

type InviteUserModalProps = ModalProps & {
  onAddUser: (email: string) => Promise<unknown>;
  organisationLogo?: string | null;
};

const Logo = styled.img`
  width: 1.5rem;
`;

const StyledTitle = styled(Layout.Group)``;

export const InviteUserModal = ({
  onAddUser,
  organisationLogo,
  ...props
}: InviteUserModalProps) => {
  const [email, setEmail] = useState("");

  return (
    <Modal
      // @ts-expect-error
      title={
        <StyledTitle>
          {organisationLogo && <Logo src={organisationLogo} />}
          <H4>Invite to your workspace</H4>
        </StyledTitle>
      }
      primaryButton={{
        title: "Send invite",
        onClickWithLoading: () => onAddUser(email)
      }}
      style={{
        width: 512
      }}
      {...props}
    >
      <TextInput
        label="Email"
        type="text"
        placeholder="email@company.com"
        value={email}
        onChange={setEmail}
      />
    </Modal>
  );
};
