import { Block } from "components/atoms";

import { StepEditorAccordion } from "../../../workflows/routes/WorkflowEditor/StepEditorAccordion";
import { api } from "../../../../services/rpc/RpcProvider";
import { Title } from "@introist/react-foundation/v2";

type SlackQuestionJourneyStepEditorProps = {
  questionId?: string;
  index?: number;
};
export const SlackQuestionJourneyStepEditor = ({
  questionId,
  index
}: SlackQuestionJourneyStepEditorProps) => {
  if (!questionId) return null;

  const { data: question } = api.questions.find.useQuery({ id: questionId });

  if (!question) return null;

  return (
    <Block blended compact>
      <StepEditorAccordion title="Answer" stepOrder={index}>
        <StepEditorAccordion.Row label="Answer">
          <Title variant="bold">{question.answer ?? "No answer yet"}</Title>
        </StepEditorAccordion.Row>
      </StepEditorAccordion>
    </Block>
  );
};
