import styled from "styled-components";
import {
  Button,
  Card,
  H3,
  Icon,
  InfoCard,
  Layout,
  Stack,
  Title,
  useTheme,
  useToast
} from "@introist/react-foundation/v2";

import { JourneyOverview, startJourney } from "services/api/journeys/JourneyApi";

import { Avatar } from "components/molecules";
import { IconPickerIcon } from "components/organisms";

import { useNavigate } from "react-router";
import { CancelReasonCard } from "modules/journeys/JourneyStepEditor/JourneyStepStatusBlock";
import { Automation } from "../hooks/useAutomations";
import { useEmployee } from "../../employees/hooks/useEmployees";
import { JourneyActions } from "../../journeys/components/JourneyActions";
import moment from "moment/moment";

type AutomationHeaderProps = {
  automation: Automation;
  onUpdate: () => Promise<void>;
};

const VerticalDivider = styled(Layout.Divider)`
  width: 1px;
  height: 1rem;
`;

const EmployeeInfoButton = styled.button`
  display: flex;
  align-items: center;
  gap: var(--spacing-medium);
  padding-right: var(--spacing-medium);
  border: 0;
  background-color: transparent;

  :hover {
    cursor: pointer;
  }
`;

export const AutomationHeader = ({ automation, onUpdate, ...rest }: AutomationHeaderProps) => {
  const { theme } = useTheme();
  const toast = useToast();
  const navigate = useNavigate();

  const { employee } = useEmployee({ id: automation.employeeId! });

  return (
    <Card {...rest} spacing="xLarge">
      <Layout.Group vertical gap="xLarge">
        <Layout.Group vertical gap="small">
          <Stack justifyContent="space-between">
            <Layout.Group>
              <IconPickerIcon fallbackIcon="workflows" />
              <H3>{automation.title}</H3>
            </Layout.Group>
            <Stack>
              {automation.status === "draft" && (
                <Button
                  key={"start-journey"}
                  onClickWithLoading={() =>
                    startJourney({ journeyId: automation.id })
                      .then(async () => {
                        await onUpdate();
                        toast.success("Automation started");
                      })
                      .catch(() => toast.error("Failed to start automation"))
                  }
                >
                  Start automation
                </Button>
              )}
              <JourneyActions journey={automation as unknown as JourneyOverview} />
            </Stack>
          </Stack>
        </Layout.Group>
        <Layout.Divider />
        <Layout.Group justifyContent="space-between">
          <Layout.Group>
            {employee && (
              <>
                <EmployeeInfoButton onClick={() => navigate(`/employees/${employee.id}`)}>
                  <Avatar nameOrEmail={employee.name ?? ""} />
                  <Title variant="bold">{employee.name || employee.email}</Title>
                </EmployeeInfoButton>
              </>
            )}
            {automation.status === "draft" && (
              <>
                <VerticalDivider />
                <InfoCard
                  icon="progress"
                  title={"This workflow is a draft"}
                  style={{ padding: `${theme.spacing.small} ${theme.spacing.medium}` }}
                />
              </>
            )}
            {automation.status === "canceled" && (
              <>
                <VerticalDivider />
                <CancelReasonCard reason="This workflow has been canceled" />
              </>
            )}
          </Layout.Group>
          <Stack>
            <Stack gap="medium">
              <Icon name="globe" color={theme.palette.foreground.subdued} />
              <Title variant="bold">{automation.timezone}</Title>
            </Stack>
            <VerticalDivider />
            <Stack>
              <Icon name="calendarTarget" color={theme.palette.foreground.subdued} />
              <Title variant="bold">{moment(automation.targetDate).format("MMMM D, YYYY")}</Title>
            </Stack>
          </Stack>
        </Layout.Group>
      </Layout.Group>
    </Card>
  );
};
