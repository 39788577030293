import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Icon,
  IconName,
  Input,
  Layout,
  Option,
  Select,
  useTheme
} from "@introist/react-foundation/v2";

import { StepTrigger, StepTriggerPeriod } from "services/api/WorkflowApi";
import styled, { css } from "styled-components";

type Props = {
  onChange: (trigger: StepTrigger) => void;
  trigger: StepTrigger;
  disableSide?: boolean;
  minAmount?: number;
  suffix?: string;
  disabled?: boolean;
  supportHours?: boolean;
  vertical?: boolean;
  startIcon?: IconName;
  immediatelyOnly?: boolean;
};

const periodOptions: Option[] = [
  {
    key: "days",
    title: "days"
  },
  {
    key: "weeks",
    title: "weeks"
  },
  {
    key: "months",
    title: "months"
  }
];

const StyledTriggerDayPicker = styled(Layout.Group)`
  .number-input-wrapper {
    width: 4.75rem;
    flex-shrink: 0;
  }

  ${({ vertical }) =>
    vertical &&
    css`
      .number-input-wrapper {
        width: 50%;
        box-sizing: border-box;
      }
    `}
`;

export const TriggerDayPicker = ({
  onChange,
  trigger,
  disableSide = false,
  minAmount = 1,
  suffix,
  disabled,
  vertical = false,
  startIcon,
  supportHours,
  immediatelyOnly
}: Props) => {
  const { theme } = useTheme();
  const calculateAmountAndPeriodWidth = useCallback(() => {
    if (["before", "after"].includes(trigger.side)) {
      return "206px";
    } else {
      return "0";
    }
  }, [trigger.side]);
  const [amountAndPeriodWidth, setAmountAndPeriodWidth] = useState<"0" | "206px">(
    calculateAmountAndPeriodWidth()
  );

  useEffect(() => {
    setAmountAndPeriodWidth(calculateAmountAndPeriodWidth());
  }, [calculateAmountAndPeriodWidth]);

  const resolvedPeriodOptions = supportHours
    ? [{ key: "hours", title: "hours" }, ...periodOptions]
    : periodOptions;

  const sideOptions: Option[] = useMemo(() => {
    const withSuffix = (str: string) => [str, suffix].join(" ");
    const options = [];

    options.push({
      key: "immediately-targetdate",
      title: "when enrolled",
      onClick: () => {
        onChange({ ...trigger, side: "immediately", reference: "targetdate" });
      }
    });
    options.push({
      key: "after-enroll",
      title: "after enroll",
      onClick: () => {
        onChange({ ...trigger, side: "after", reference: "enroll" });
      }
    });

    if (!immediatelyOnly) {
      options.push({
        key: "before-targetdate",
        title: withSuffix("before"),
        onClick: () => {
          onChange({ ...trigger, side: "before", reference: "targetdate" });
        }
      });
      options.push({
        key: "on-targetdate",
        title: withSuffix("on"),
        onClick: () => {
          onChange({ ...trigger, side: "on", reference: "targetdate" });
        }
      });
      options.push({
        key: "after-targetdate",
        title: withSuffix("after"),
        onClick: () => {
          onChange({ ...trigger, side: "after", reference: "targetdate" });
        }
      });
    }

    return options;
  }, [trigger, onChange, suffix, immediatelyOnly]);

  return (
    <StyledTriggerDayPicker vertical={vertical} gap="small">
      {startIcon && (
        <Icon
          name={startIcon}
          color={theme.palette.foreground.subdued}
          style={{ marginRight: 8 }}
        />
      )}
      <div className="number-input-wrapper">
        <Input
          value={trigger.amount}
          onChange={amount => onChange({ ...trigger, amount: parseInt(amount, 10) })}
          type="number"
          min={minAmount}
          disabled={disabled || amountAndPeriodWidth === "0"}
          size="small"
        />
      </div>
      <Select
        sameWidthWithReference
        value={trigger.period}
        onSelect={option => onChange({ ...trigger, period: option!.key as StepTriggerPeriod })}
        options={resolvedPeriodOptions}
        disabled={disabled || amountAndPeriodWidth === "0"}
        size="small"
        readonly={disabled}
      />
      {!disableSide && (
        <Select
          sameWidthWithReference
          readonly={disableSide}
          value={`${trigger.side}-${trigger.reference || "targetdate"}`}
          options={sideOptions}
          size="small"
          disabled={disabled}
        />
      )}
    </StyledTriggerDayPicker>
  );
};
