import { deleteJson, getJson, postJson } from "../../services/api/ApiBase";

export interface PublicAccountConnectDetails {
  authorizationUri: string;
  workspace: { name: string; logo: string };
}

export const getConnectionAuthorizationUrl = (provider: string): Promise<string> => {
  return getJson(`/connectedaccounts/authorize?provider=${provider}`);
};

export const completeAccountConnection = (provider: string, code: string) => {
  return getJson(`/connectedaccounts/callback/${provider}?code=${code}`);
};

export const archiveConnectedAccount = (id: string) => {
  return deleteJson(`/connectedaccounts/${id}`);
};

export const startPublicAccountConnect = (
  workspaceToken: string,
  provider: string
): Promise<PublicAccountConnectDetails> => {
  return postJson("/connect/start", { workspaceToken, provider });
};

export const completePublicAccountConnect = (code: string, state: string) => {
  return postJson("/connect/complete", { code, state });
};

export interface SubAccountCreate {
  name: string;
  email: string;
}

export const saveSubAccount = (accountId: string, subAccount: SubAccountCreate) => {
  return postJson(`/connectedaccounts/${accountId}/subaccount`, subAccount);
};
