import { WorkflowStepEditor } from "../../../workflows/routes/WorkflowEditor/StepEditor";
import { WorkflowStepActiveEmployees } from "../../../workflows/routes/WorkflowEditor/StepEditor/StepJourneyStatus/ActiveEmployees";
import { Drawer } from "@introist/react-foundation/v2";
import { useCallback, useState } from "react";
import { WorkflowStep } from "../../hooks/useWorkflowSteps";

export type StepEditorMode = "workflow" | "nudge";

type WorkflowStepEditorDrawerProps = {
  open: boolean;
  onClose: () => void;
  workflowId: string;
  step: WorkflowStep;
  mode?: StepEditorMode;
};

export const WorkflowStepEditorDrawer = ({
  open,
  onClose,
  workflowId,
  step,
  mode = "workflow"
}: WorkflowStepEditorDrawerProps) => {
  const [activeEmployeesOpen, setActiveEmployeesOpen] = useState(false);

  const [hasChanges, setHasChanges] = useState(false);

  const close = useCallback(
    (force?: boolean) => {
      setActiveEmployeesOpen(false);
      if (!hasChanges) onClose();
      else if (force === true) onClose();
    },
    [hasChanges, onClose]
  );

  return (
    <Drawer open={open} width="540px" onClose={close}>
      {open && step && !activeEmployeesOpen && (
        <WorkflowStepEditor
          workflowId={workflowId}
          workflowStep={step}
          onEditStateChanged={setHasChanges}
          onOpenActiveEmployees={() => setActiveEmployeesOpen(true)}
          onClose={close}
          mode={mode}
        />
      )}
      {open && activeEmployeesOpen && (
        <WorkflowStepActiveEmployees
          stepId={step.id!}
          onClose={() => setActiveEmployeesOpen(false)}
        />
      )}
    </Drawer>
  );
};
