import { IconButton, Stack } from "@introist/react-foundation/v2";
import { api } from "services/rpc/RpcProvider";
import styled from "styled-components";
import { ContentType } from "../schema";
import { ErrorCard } from "./ErrorCard";
import { TextInput } from "../../../components/atoms/TextInput";
import { useState } from "react";
import { Guide } from "./Guide";

interface InstructionInputProps {
  contentType: ContentType;
  onSubmit: () => Promise<void>;
  value: string;
  onChange: (value: string) => void;
  showInput: boolean;
  setShowInput: (showInput: boolean) => void;
  error?: { message?: string; code?: number };
  clearError: () => void;
  isLoading: boolean;
  isElevated: boolean;
}

const Wrapper = styled(Stack)<{ elevated: boolean }>`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0;
  border-radius: var(--rounding-medium);
  ${({ elevated }) => elevated && `box-shadow: var(--shadow-default);`}
`;

const AIButton = styled(IconButton)<{ isOpen: boolean }>`
  height: 38px;
  width: 38px;
  background-color: var(--palette-surface-subdued);
  border-color: var(--palette-surface-subdued);
  &:hover {
    background-color: var(--palette-surface-default);
    border-color: var(--palette-surface-default);
  }
  ${({ isOpen }) =>
    isOpen &&
    `
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    `}
`;

export const InstructionInput = ({
  contentType,
  onSubmit,
  value,
  onChange,
  showInput,
  setShowInput,
  error,
  clearError,
  isLoading,
  isElevated
}: InstructionInputProps) => {
  const { data } = api.ai.workspace.find.useQuery({});
  const [refElement, setRefElement] = useState<HTMLElement | null>(null);
  const aiEnabled = !!data;
  return (
    <Wrapper ref={setRefElement} elevated={isElevated}>
      <AIButton
        icon={showInput ? "crossLarge" : "glow"}
        variant="blended"
        size="large"
        onClick={() => aiEnabled && setShowInput(!showInput)}
        isOpen={showInput}
      />
      {showInput ? (
        <TextInput
          size="small"
          placeholder="Give instructions and press 'Enter'"
          value={value}
          onChange={onChange}
          onKeyUp={e => e.key === "Enter" && onSubmit()}
          autoFocus
          endAdornment={
            <IconButton
              size="small"
              icon="glow"
              onClickWithLoading={onSubmit}
              loading={isLoading}
            />
          }
          style={{
            borderTopLeftRadius: "0",
            borderBottomLeftRadius: "0",
            borderColor: "transparent",
            minWidth: "380px",
            width: "100%",
            boxSizing: "border-box"
          }}
        />
      ) : (
        <Guide
          contentType={contentType}
          onClick={() => aiEnabled && setShowInput(true)}
          isEnabled={aiEnabled}
        />
      )}
      {error && (
        <ErrorCard
          error={error}
          onClick={onSubmit}
          refElement={refElement}
          clearError={() => clearError()}
        />
      )}
    </Wrapper>
  );
};
