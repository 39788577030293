import { useTheme } from "@introist/react-foundation/v2";
import * as d3 from "d3";
import { useMemo } from "react";

type Props = {
  domain: Date[];
  range: number[];
  bottom?: boolean;
  withLines?: boolean;
  boundedHeight: number;
  boundedWidth: number;
};

export const Axis = ({ domain, range, bottom, withLines, boundedHeight, boundedWidth }: Props) => {
  const { theme } = useTheme();

  const ticks = useMemo(() => {
    const xScale = d3.scaleUtc().domain(domain).range(range).nice();

    const pixelsPerTick = 150;
    const numberOfTicksTarget = Math.max(1, Math.floor(boundedWidth / pixelsPerTick));

    const ticks = xScale.ticks(numberOfTicksTarget);

    const formatTime = d3.utcFormat("%b %d, %y");

    return ticks.map(value => ({
      value: formatTime(value),
      xOffset: xScale(value)
    }));
  }, [domain, range, boundedWidth]);

  const lineColor = theme.palette.foreground.dimmed;

  const d = bottom
    ? ["M", range[0], boundedHeight + 6, "v", -6, "H", range[1], "v", 6].join(" ")
    : ["M", range[0], -6, "v", 6, "H", range[1], "v", -6].join(" ");

  return (
    <g className="axis">
      <path fill="none" stroke={lineColor} d={d} />
      {ticks.map(({ value, xOffset }) => (
        <g
          key={`${value.toString()}`}
          transform={`translate(${xOffset}, ${bottom ? boundedHeight + 6 : 0})`}
        >
          <line y2="-6" stroke={lineColor} />
          <text
            key={value}
            style={{
              fontSize: 10,
              textAnchor: "middle",
              transform: bottom ? "translateY(16px)" : "translateY(-16px)",
              fontFamily: "Poppins",
              fill: theme.palette.foreground.subdued
            }}
          >
            {value}
          </text>
        </g>
      ))}
      {withLines &&
        ticks.map(t => (
          <line
            key={`axis-line-${t.value}`}
            stroke={theme.palette.border.subdued}
            strokeDashoffset={3}
            strokeDasharray={3}
            y2={boundedHeight}
            y1={0}
            transform={`translate(${t.xOffset}, 0)`}
          />
        ))}
    </g>
  );
};
