import {
  Title,
  Field,
  useTheme,
  Input,
  Select,
  Toggle,
  InfoCard
} from "@introist/react-foundation/v2";

import { Block } from "components/atoms";
import { HtmlEditor, ReadOnlyEmailMessage } from "components/organisms";

import editorStyles from "../WorkflowStepEditor.module.scss";
import { useEmailSenderApi } from "services/api/Senders";
import { useMemo } from "react";
import { PrefixedForm } from "utils/FormUtils";
import styled from "styled-components";

interface Props {
  prefixedForm: PrefixedForm;
  readonly?: boolean;
}

const ToggleContainer = styled.div`
  display: flex;
  gap: var(--spacing-medium);
  align-items: center;
  margin-bottom: var(--spacing-large);
`;

export const InstructionEmailEditor = ({ prefixedForm, readonly }: Props) => {
  const { theme } = useTheme();

  const senderApi = useEmailSenderApi();
  const { data: senderData } = senderApi.list({ filterCalendarOnlySenders: true }).query;

  const senders = useMemo(() => {
    const senders = [{ key: "default", title: "Introist" }];
    if (senderData) {
      senderData.forEach(sender => senders.push({ key: sender.id, title: sender.name }));
    }
    return senders;
  }, [senderData]);

  const sendInstructions = useMemo(() => prefixedForm.get("sendInstructions"), [prefixedForm]);

  return (
    <Block>
      {!readonly && (
        <ToggleContainer>
          <Toggle
            checked={prefixedForm.get("sendInstructions")}
            onChange={prefixedForm.set("sendInstructions")}
          />
          <Title>Send instructions email</Title>
        </ToggleContainer>
      )}
      <InfoCard title={sendInstructions ? "Instructions email" : "Remember to send instructions"}>
        {!sendInstructions && (
          <Title>
            Instructions to login are not sent to the employee automatically so you must setup
            another message to send them. Initial password is saved to attribute{" "}
            <strong>Initial Google password</strong> which can be used in other steps.
          </Title>
        )}
        {sendInstructions && (
          <Title>
            {readonly
              ? `Initial login information was sent to Employee using the following message.`
              : `Initial login information will be sent to Employee using the following email template.
        Initial password will be generated automatically. You must include variables {{username}}
        and {{password}} in the message.`}
          </Title>
        )}
      </InfoCard>

      {sendInstructions && (
        <>
          <div className={editorStyles.DrawerGrid} style={{ margin: `${theme.spacing.xLarge} 0` }}>
            <Title>From</Title>
            <Select
              options={senders}
              size="small"
              variant="blended"
              value={prefixedForm.get("instructionMessageTemplate.from")}
              onSelect={opt => {
                prefixedForm.set("instructionMessageTemplate.from")(opt.key);
              }}
              style={{ width: "100%" }}
              readonly={readonly}
            />
            <Title>To</Title>
            <Select
              size="small"
              variant="blended"
              readonly
              options={[]}
              value={
                readonly ? prefixedForm.get("instructionMessage.to") : "Employees private email"
              }
              style={{ width: "100%" }}
            />
          </div>
          {readonly && (
            <ReadOnlyEmailMessage
              subject={prefixedForm.get("instructionMessage.subject")}
              message={prefixedForm.get("instructionMessage.message")}
            />
          )}

          {!readonly && (
            <>
              <Field title="Subject">
                <Input
                  size="small"
                  value={prefixedForm.get("instructionMessageTemplate.subject")}
                  onChange={prefixedForm.set("instructionMessageTemplate.subject")}
                />
              </Field>
              <Field title="Message">
                <HtmlEditor
                  defaultValue={prefixedForm.get("instructionMessageTemplate.message")}
                  onChange={prefixedForm.set("instructionMessageTemplate.message")}
                />
              </Field>
            </>
          )}
        </>
      )}
    </Block>
  );
};
