import { TopBarRoute } from "../../../../components/templates";
import { useNavigate, useParams } from "react-router";
import { useNudge, useNudgeActions } from "../../useNudges";
import { useAutomations } from "../../../automations/hooks/useAutomations";
import { Skeletons } from "../../../../components/molecules";
import {
  ActionMenu,
  Button,
  Card,
  Icon,
  IconButton,
  Layout,
  Modal,
  Stack,
  Title,
  useToast
} from "@introist/react-foundation/v2";
import { NudgeConfig } from "./NudgeConfig";
import { useCallback, useMemo, useState } from "react";
import { NudgePreview } from "./NudgePreview";
import { api } from "../../../../services/rpc/RpcProvider";
import { compact } from "lodash";
import { NudgeHistory } from "./NudgeHistory";
import { Dot } from "../../../../components/atoms";
import { NudgeHeading } from "./NudgeHeading";
import { NudgeSettingsModal } from "./NudgeSettingsModal";
import { useEmployeeGroups } from "../../../employees/hooks/useEmployeeGroups";
import { parseRelativeDate } from "@introist/introist-commons/dist";
import { stringifyUnit } from "../../../../components/organisms";
import { useHasRole, UserRole } from "../../../../IdentityProvider";

export const NudgeRoute = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { id } = useParams();

  const isSuperAdmin = useHasRole(UserRole.SuperAdmin);

  const { groups } = useEmployeeGroups();

  const { nudge } = useNudge(id!);

  const { data: trigger, refetch: refetchTrigger } = api.triggers.find.useQuery(
    { id: nudge?.triggerId ?? "" },
    {
      enabled: !!nudge
    }
  );
  const updateTrigger = api.triggers.update.useMutation();

  const { remove } = useNudgeActions();

  const { automations } = useAutomations(
    { workflowId: nudge?.workflowId },
    {
      enabled: !!nudge
    }
  );

  const cooldown = useMemo(() => {
    if (!trigger) return "";
    const rel = parseRelativeDate(trigger.cooldownPeriod!);
    return `${rel.amount} ${stringifyUnit(rel.unit)}`;
  }, [trigger]);

  const updateActive = useCallback(
    async (active: boolean) => {
      await updateTrigger
        .mutateAsync({ id: nudge!.triggerId, updates: { active } })
        .then(async () => {
          await refetchTrigger();
          if (active) toast.success("Nudge activated");
          if (!active) toast.success("Nudge inactivated");
        })
        .catch(() => {
          toast.error("Failed to update nudge");
        });
    },
    [nudge, toast, updateTrigger, refetchTrigger]
  );

  const [showSettingsEdit, setShowSettingsEdit] = useState(false);
  const [editSettings, setEditSettings] = useState(false);

  const [previewOpen, setPreviewOpen] = useState(false);

  return (
    <TopBarRoute
      paths={[
        { key: "nudges", title: "Nudges", onClick: () => navigate("/nudges") },
        { key: "nudge", title: nudge?.name || "" }
      ]}
      medium
    >
      <Stack vertical>
        {(!nudge || !automations) && <Skeletons.Block height={100} />}
        {nudge && automations && (
          <Card style={{ padding: "var(--spacing-xLarge)" }}>
            <Stack vertical gap="xLarge">
              <Stack>
                <NudgeHeading nudge={nudge} />
                <Stack style={{ marginLeft: "auto" }}>
                  {trigger && trigger.active && (
                    <Stack>
                      <Icon name="cycle" />
                      <Title>This nudge is active.</Title>
                    </Stack>
                  )}
                  {trigger && !trigger.active && (
                    <Stack>
                      <Title>This nudge is inactive.</Title>
                      <Button
                        variant="outlined"
                        startIcon="preview"
                        onClick={() => setPreviewOpen(true)}
                      >
                        Preview
                      </Button>
                      <Button startIcon="cycle" onClickWithLoading={() => updateActive(true)}>
                        Activate
                      </Button>
                    </Stack>
                  )}
                  <ActionMenu
                    options={compact([
                      trigger &&
                        trigger.active && {
                          key: "inactivate",
                          title: "Inactivate",
                          startAdornmentIcon: "pause",
                          onClick: () => updateActive(false)
                        },
                      trigger &&
                        trigger.active && {
                          key: "preview",
                          title: "Preview",
                          startAdornmentIcon: "preview",
                          onClick: () => setPreviewOpen(true)
                        },
                      {
                        key: "delete",
                        title: "Delete",
                        startAdornmentIcon: "trash",
                        onClick: () => remove(nudge.id, "/nudges")
                      },
                      isSuperAdmin && {
                        key: "trigger",
                        title: "Show trigger",
                        startAdornmentIcon: "trigger",
                        onClick: () => window.open(`/triggers/${nudge.triggerId}`, "_blank")
                      },
                      isSuperAdmin && {
                        key: "workflow",
                        title: "Show workflow",
                        startAdornmentIcon: "workflows",
                        onClick: () => window.open(`/flows/edit/${nudge.workflowId}`, "_blank")
                      }
                    ])}
                  />
                </Stack>
              </Stack>
              <Layout.Divider />
              <Stack
                onMouseEnter={() => setShowSettingsEdit(true)}
                onMouseLeave={() => setShowSettingsEdit(false)}
              >
                <Icon name="persons" dimmed />
                <Title>
                  {trigger?.employeeGroupId
                    ? (groups ?? []).find(g => g.id === trigger.employeeGroupId)?.title
                    : "All employees"}
                </Title>
                <Dot />
                <Title>at most every {cooldown}</Title>
                <IconButton
                  size="small"
                  icon={<Icon name="pencil" dimmed />}
                  style={{ display: showSettingsEdit ? "block" : "none" }}
                  onClick={() => setEditSettings(true)}
                />
              </Stack>
            </Stack>
          </Card>
        )}
        <Stack vertical style={{ maxWidth: "900px", margin: "auto" }}>
          {nudge && <NudgeConfig nudge={nudge} />}
          {nudge && (
            <div style={{ marginTop: "64px" }}>
              <NudgeHistory nudge={nudge} />
            </div>
          )}
          {nudge && (
            <Modal title="Preview nudge" open={previewOpen} onClose={() => setPreviewOpen(false)}>
              <NudgePreview nudge={nudge} />
            </Modal>
          )}
          {nudge && (
            <NudgeSettingsModal
              nudge={nudge}
              open={editSettings}
              onClose={() => setEditSettings(false)}
            />
          )}
        </Stack>
      </Stack>
    </TopBarRoute>
  );
};
