import { Nudge } from "../../useNudges";
import { CollapseList, ListRow } from "../../../../components/organisms";
import { Card, Icon, Stack, Tag, Title } from "@introist/react-foundation/v2";
import { useState } from "react";
import { Automation, useAutomations } from "../../../automations/hooks/useAutomations";
import { formatDateTime } from "../../../../utils/DatesUtils";
import { DateFormats } from "../../../../utils/dates";
import { orderBy } from "lodash";

export const NudgeHistory = ({ nudge }: { nudge: Nudge }) => {
  const [open, setOpen] = useState(false);

  const { automations } = useAutomations({
    workflowId: nudge.workflowId,
    stages: ["inprogress", "ended"]
  });

  const renderStatus = (automation: Automation) => {
    if (automation.completedAt) return <Tag colorVariant="success">Completed</Tag>;
    if (automation.status === "canceled") return <Tag>Canceled</Tag>;
    if (automation.failingStepCount > 0) return <Tag colorVariant="danger">Failing</Tag>;
    return <Tag colorVariant="primary">Pending</Tag>;
  };

  return (
    <CollapseList
      open={open}
      onToggleOpen={() => setOpen(!open)}
      header={
        <Stack gap="small">
          <Icon name="history" dimmed />
          <Title>History</Title>
          {automations && <Title small>{automations.length}</Title>}
        </Stack>
      }
    >
      {orderBy(automations ?? [], "createdAt", "desc").map(a => {
        return (
          <ListRow
            key={a.id}
            gridTemplateColumns="1fr auto 120px"
            onClick={() => window.open(`/automations/${a.id}`, "_blank")}
          >
            <Title variant="bold">{a.employeeName}</Title>
            <Title>{formatDateTime(a.completedAt ?? a.createdAt, DateFormats.datetime.long)}</Title>
            <div style={{ marginLeft: "auto" }}>{renderStatus(a)}</div>
          </ListRow>
        );
      })}
      {automations && automations.length === 0 && (
        <Stack style={{ padding: "var(--spacing-large)" }}>
          <Card variant="blended" style={{ flex: 1 }}>
            <Title style={{ textAlign: "center" }}>This nudge has not been used yet</Title>
          </Card>
        </Stack>
      )}
    </CollapseList>
  );
};
