import { useRef, useState } from "react";
import {
  DataForm,
  TimeRange,
  useResponsive,
  buildOptions,
  OptionList,
  Popover,
  Layout,
  Stack
} from "@introist/react-foundation/v2";

import { Block } from "components/atoms";
import { TriggerDayPicker } from "components/molecules";

import { StepTrigger } from "services/api/WorkflowApi";
import { stringifyTrigger } from "utils/Text";

import { PopupTimeRangePickerV2 } from "modules/workflows/components/PopupTimeRangePicker/v2";
import { IconToggleButton } from "modules/workflows/components/IconToggleButton";
import { stringifyRange } from "modules/workflows/components/PopupTimeRangePicker";

import { StepEditorAccordion } from "../../StepEditorAccordion";
import { CardPopover } from "../../CardPopover";
import { TimeFinderEditor } from "./components";
import { StepTypeV2, StepTypesV2 } from "services/rpc/RpcProvider";
import { FallbackDirectionEditor } from "./components/FallbackDirectionEditor";
import { isStepEvent } from "./util";
import { RelativeDateButtonEditor } from "../../../../../../components/molecules/RelativeDate/RelativeDateButtonEditor";
import { WorkflowStepTypes } from "../../../../../flows/stepTypes";
import { StepCutoffEditor } from "../../../../../flows/containers/StepCutoffEditor";

type ScheduleBlockProps = {
  form: DataForm<any>;
  targetDateTerm: string;
  immediatelyOnly?: boolean;
};

const DEFAULT_TIME_PICKER_OPTIONS = buildOptions({ start: "00:00", end: "23:59" }, 15);

export const ScheduleBlock = ({
  form,
  targetDateTerm,
  immediatelyOnly,
  ...rest
}: ScheduleBlockProps) => {
  const { isMobileLayout } = useResponsive();

  const trigger = form.get("trigger") as unknown as StepTrigger;
  const stepType = form.get("stepType") as StepTypeV2;
  const isEvent = isStepEvent(stepType);
  const isCommonEvent = isEvent && form.get("stepData.eventType") === "common";
  const isMeeting = isEvent && form.get("stepData.eventType") === "individual";

  const onChangeDay = (trigger: StepTrigger) => {
    form.set("trigger")(trigger);
  };

  const getEventTimeRange = () => {
    return {
      start: form.get("stepData.fromTime"),
      end: form.get("stepData.toTime")
    };
  };

  const setEventTimeRange = (range: TimeRange) => {
    form.set("stepData.fromTime")(range.start);
    form.set("stepData.toTime")(range.end);
  };

  const triggerDayPickerButtonRef = useRef<HTMLButtonElement | null>(null);
  const [triggerDayPickerOpen, setTriggerDayPickerOpen] = useState(false);

  const timePickerButtonRef = useRef<HTMLButtonElement | null>(null);
  const [timePickerOpen, setTimePickerOpen] = useState(false);

  const selectedTime = DEFAULT_TIME_PICKER_OPTIONS.find(({ key }) => key === trigger.time);
  const timeRangeString = stringifyRange(getEventTimeRange());

  const triggerString = stringifyTrigger(
    trigger,
    targetDateTerm !== undefined,
    false,
    targetDateTerm
  );

  const resolveTitle = () => {
    const stepType = form.data.stepType;
    const wfStepType = WorkflowStepTypes.find(type => type.stepType === stepType);

    if (
      wfStepType &&
      wfStepType.scheduleDescription &&
      wfStepType.scheduleDescription["scheduled"]
    ) {
      return wfStepType.scheduleDescription["scheduled"];
    }

    if ([StepTypesV2.Enum.eventattend].includes(stepType)) {
      return "Send Invite on";
    }

    return "Schedule";
  };

  const stepCutoff = (
    <StepCutoffEditor value={form.get("cutoffDays") ?? 90} onChange={form.set("cutoffDays")} />
  );

  return (
    <Block blended compact {...rest}>
      <StepEditorAccordion title={resolveTitle()}>
        <Layout.Group vertical>
          <StepEditorAccordion.Row label="Date">
            <Stack justifyContent="space-between">
              <IconToggleButton
                startIcon="calendar"
                ref={triggerDayPickerButtonRef}
                active={triggerDayPickerOpen}
                onClick={() => setTriggerDayPickerOpen(!triggerDayPickerOpen)}
              >
                {triggerString}
              </IconToggleButton>
              {!isEvent && trigger.side !== "immediately" && (
                <FallbackDirectionEditor
                  fallbackDirection={form.get("fallbackDirection")}
                  onChange={form.set("fallbackDirection")}
                />
              )}
              {trigger.side === "immediately" && stepCutoff}
            </Stack>
            <CardPopover
              open={triggerDayPickerOpen}
              referenceElement={triggerDayPickerButtonRef.current}
              placement={isMobileLayout ? "bottom-start" : "left-start"}
              sameWidthWithReference={isMobileLayout}
              onClose={() => {
                setTriggerDayPickerOpen(false);
              }}
            >
              <TriggerDayPicker
                vertical
                onChange={onChangeDay}
                trigger={trigger}
                suffix={targetDateTerm}
                immediatelyOnly={immediatelyOnly}
              />
            </CardPopover>
          </StepEditorAccordion.Row>
          {(trigger.side !== "immediately" || stepType === "event") && (
            <StepEditorAccordion.Row label="Time">
              <Stack justifyContent="space-between">
                {stepType === "event" ? (
                  <PopupTimeRangePickerV2
                    sameWidthWithReference
                    scrollToSelected
                    value={getEventTimeRange()}
                    onSelect={setEventTimeRange}
                    allDayEnabled={!isCommonEvent}
                  />
                ) : (
                  <>
                    <IconToggleButton
                      startIcon="time"
                      active={timePickerOpen}
                      ref={timePickerButtonRef}
                      onClick={() => setTimePickerOpen(!timePickerOpen)}
                    >
                      {isCommonEvent ? timeRangeString : trigger.time}
                    </IconToggleButton>
                    <Popover
                      open={timePickerOpen}
                      referenceElement={timePickerButtonRef.current}
                      placement={isMobileLayout ? "bottom-start" : "left-start"}
                      sameWidthWithReference={isMobileLayout}
                      onClose={() => setTimePickerOpen(false)}
                    >
                      <OptionList
                        searchable
                        scrollToSelected
                        searchPlaceholder="Search"
                        selected={selectedTime?.key}
                        options={DEFAULT_TIME_PICKER_OPTIONS}
                        onItemClick={({ key }) => {
                          form.set("trigger")({ ...trigger, time: key });
                          setTimePickerOpen(false);
                        }}
                        style={{
                          maxWidth: 120
                        }}
                      />
                    </Popover>
                  </>
                )}
                {trigger.side !== "immediately" && stepCutoff}
              </Stack>
            </StepEditorAccordion.Row>
          )}

          {isMeeting && (
            <StepEditorAccordion.Row label="Send invites">
              <RelativeDateButtonEditor
                placeholder="When enrolled"
                onChange={relDate => form.set("stepData.sendInvitesAt")(relDate)}
                value={form.get("stepData.sendInvitesAt")}
                locked={{ side: true }}
                clearable
              />
            </StepEditorAccordion.Row>
          )}

          {isEvent && (
            <TimeFinderEditor
              trigger={form.get("trigger") as unknown as StepTrigger}
              onTriggerChange={trigger => form.set("trigger")(trigger)}
              isEvent={isCommonEvent}
              triggerString={triggerString}
            />
          )}
        </Layout.Group>
      </StepEditorAccordion>
    </Block>
  );
};
