import { Icon, Tag } from "@introist/react-foundation/v2";
import { useEmployeeFields } from "../hooks/useEmployeeFields";
import { useMemo } from "react";

export const EmployeeFieldTag = ({ path }: { path: string }) => {
  const { fields } = useEmployeeFields();

  const title = useMemo(() => {
    if (!fields) return path;
    if (!path.includes(".")) return fields.find(field => field.key === path)?.title || path;
    const [parent, child] = path.split(".");
    const parentField = fields.find(field => field.key === parent);
    if (!parentField) return path;
    const childField = fields.find(field => field.key === child);
    if (!childField) return path;
    return `${parentField.title} - ${childField.title}`;
  }, [fields, path]);

  return <Tag startAdornment={<Icon name="attribute" style={{ width: "12px" }} />}>{title}</Tag>;
};
